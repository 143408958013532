import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    FileUploader,
    Headers as uploadHeader,
    FileItem,
    ParsedResponseHeaders,
} from 'ng2-file-upload';
import { environment as env } from '../../../../environments/environment';
import { ObservableDataService } from 'app/core/services/observable-data.service';

// const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

const URL = env.UpLink_ApiUrl + 'api/filemanager/file/upload';
const URL_excel = env.UpLink_ApiUrl + 'api/products/excel/upload/{id}';

@Component({
    selector: 'glink-upload-excel',
    templateUrl: './upload-excel.component.html',
    styleUrls: ['./upload-excel.component.scss'],
    providers: [],
})
export class UploadExcelComponent implements OnInit {
    public uploader: FileUploader;
    public hasBaseDropZoneOver = false;
    public hasAnotherDropZoneOver = false;
    public headers: UploadHeader[];
    public header: UploadHeader;
    @Input() categoryId: number;
    companyId: number;
    url_file: FileItem;
    isWaiting = false;
    retorno: string;
    @Input() path: string;
    @Output() saveExcel = new EventEmitter<any>();

    constructor(private ods: ObservableDataService) {}

    ngOnInit(): void {
        this.header = new UploadHeader();
        this.header.name = 'apiKey';
        this.header.value = env.apiKey;
        this.headers = [];
        this.headers.push(this.header);
        this.companyId = this.ods.getLoggedUser().companyId;
        this.path = '/' + this.companyId + '/excel/productos';
        var urlApi = URL_excel.replace('{id}', this.categoryId.toString());

        this.uploader = new FileUploader({
            url: urlApi,
            headers: this.headers,
            additionalParameter: {
                companyId: this.companyId,
                path: this.path,
            },
        });

        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
            this.url_file = item;
            this.retorno = '';
            this.isWaiting = true;
        };
        this.uploader.onErrorItem = (item, response, status, headers) =>
            this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem = (item, response, status, headers) =>
            this.onSuccessItem(item, response, status, headers);
    }

    onSuccessItem(
        item: FileItem,
        response: any,
        status: number,
        headers: ParsedResponseHeaders
    ): any {
        this.isWaiting = false;
        const data = JSON.parse(response); // success server response

        this.saveExcel.emit(response);

        /*data.forEach(element => {
            this.retorno = element.parent.sqlMessage;
            });*/

        this.retorno = response.replace(/['"]+/g, '');

        /*this.retorno = "Exito !!";*/

        this.uploader.clearQueue();
    }

    onErrorItem(
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders
    ): any {
        const error = JSON.parse(response); // error server response
        this.saveExcel.emit(response);
        this.isWaiting = false;
    }
}

class UploadHeader implements uploadHeader {
    name: string;
    value: string;

    constructor() {}
}
