import { ModelBase } from '../../../../shared/models/model.base';

export class ShippingOrder extends ModelBase {
    companyId: number;
    customerId: number;
    shippingStatusId: number;
    saleOrderId: number;
    reference = '';
    description = '';
    shippingEstimatedDate: Date = null;
    createdAt: Date = null;
    shippingAddress: any;
    billingAddress: any;
    region: any;
    provincia: any;
    ciudad: any;

    constructor() {
        super();
    }

    getTitle() {
        if (this.reference) {
            return `${this.reference} - ${this.description}`;
        } else {
            return `${this.saleOrderId}`;
        }
    }
}
