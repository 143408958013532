import {
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    style,
    animate,
    AnimationBuilder,
    AnimationPlayer,
} from '@angular/animations';
import { Subscription } from 'rxjs/Subscription';
import { glinkAnimations } from '../../../core/animations';

@Component({
    selector: 'glink-filter-panel',
    templateUrl: './filter-panel.component.html',
    styleUrls: ['./filter-panel.component.scss'],
    animations: glinkAnimations,
})
export class FilterPanelComponent implements OnInit {
    @ViewChild('openButton') openButton;
    @ViewChild('panel') panel;
    @ViewChild('overlay') overlay: ElementRef;

    public player: AnimationPlayer;

    @HostBinding('class.bar-closed') barClosed: boolean;

    constructor(
        private animationBuilder: AnimationBuilder,
        private renderer: Renderer2
    ) {
        this.barClosed = true;
    }

    ngOnInit() {
        this.renderer.listen(this.overlay.nativeElement, 'click', () => {
            this.closeBar();
        });
    }

    closeBar() {
        this.player = this.animationBuilder
            .build([
                style({ transform: 'translate3d(0,0,0)' }),
                animate(
                    '400ms ease',
                    style({ transform: 'translate3d(100%,0,0)' })
                ),
            ])
            .create(this.panel.nativeElement);

        this.player.play();

        this.player.onDone(() => {
            this.barClosed = true;
        });
    }

    openBar() {
        this.barClosed = false;

        this.player = this.animationBuilder
            .build([
                style({ transform: 'translate3d(100%,0,0)' }),
                animate(
                    '400ms ease',
                    style({ transform: 'translate3d(0,0,0)' })
                ),
            ])
            .create(this.panel.nativeElement);

        this.player.play();
    }
}
