import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ObservableDataService } from 'app/core/services/observable-data.service';


@Injectable()
export class UnLoggedUserGuard implements CanActivate {

    constructor(private router: Router, private ods: ObservableDataService, private jwt: JwtHelperService) {
    }

    canActivate() {

      
        const sessionInfo = this.ods.getLoggedUser();
     

        if (!sessionInfo.token) {
            return true;
        }

        this.router.navigate(['/webhome']);
        return false;
    }
}
