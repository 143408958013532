import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RecordAction } from '../../../../../../shared/enums';
import { Module } from '../../../../models/module.type';
import { Action } from '../../../../models/action.type';
import { SubModule } from 'app/admin/roles/models/submodule.type';

@Component({
  selector: 'role-action-form',
  templateUrl: './role-action-form.component.html',
  styleUrls: ['./role-action-form.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class RoleActionFormComponent {

  module: Module;
  dialogTitle: string;
  callback: (actions: any) => void;

  recordAction = RecordAction;

  actions = [];

  constructor(
    public dialogRef: MatDialogRef<RoleActionFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.module = data.module;
    this.callback = data.callback;
    this.dialogTitle = `Módulo - ${this.module.description}`;
  }

  onChange(subModule: SubModule, action: Action, event: any) {
    action.allow = event.source.checked;

    subModule.availableActions = action.allow
      ? subModule.availableActions + 1
      : subModule.availableActions - 1;
  }

  save() {
    this.callback(this.module.subModules);
  }
}
