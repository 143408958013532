import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { ErrorType } from '../models/error.type';
import * as toastr from 'toastr';
import { Router } from '@angular/router';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(ex: any) {
        console.log(ex);

        const error = this.getErrorInfo(ex);
        let destination;

        switch (error.code) {
            case 401:
                destination = '/login';
                error.title = 'Sin Autorización';
                error.errors.push(
                    'Necesitas Iniciar sesión para acceder a este recurso'
                );
                break;
        }

        // Show the message.
        toastr.error(ex.error.status, ex.error.message);

        if (destination) {
            const router = this.injector.get(Router);
            router.navigate([destination]);
        }
    }

    getErrorInfo(ex: any): ErrorType {
        const error = new ErrorType();
        let body;
        if (ex.status) {
            error.code = ex.status;
        }

        if (ex._body) {
            try {
                body = JSON.parse(ex._body);

                if (body.error && body.error_description) {
                    error.title = body.error;
                    error.errors.push(body.error_description);
                } else {
                    if (body.message) {
                        error.title = body.message;
                    }

                    if (body.modelState) {
                        error.errors = this.getErrorsFromModelState(
                            body.modelState
                        );
                    }
                }
            } catch (ex) {
                error.title = 'Unable to process the request.';
                error.errors.push('Please try later.');
            }
        } else {
            if (ex.statusText) {
                error.errors.push(ex.statusText);
            } else if (ex.message) {
                error.errors.push(ex.message);
            }
        }

        return error;
    }

    getErrorsFromModelState(data: any): Array<string> {
        const result = {
            message: [],
        };

        Object.keys(data).forEach((key) => {
            const value = data[key];
            this.processObjectProperty(value, result);
        });

        return result.message;
    }

    processObjectProperty(property: any, result: any): string {
        if (Array.isArray(property)) {
            property.forEach((item) => {
                this.processObjectProperty(item, result);
            });
        } else {
            result.message.push(property);
            return result;
        }
    }
}
