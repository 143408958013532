import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'glink-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class ImageUploadComponent implements OnInit {

  @Input() imageFieldName: string;
  @Input() logoUrl: string;

  @Output() change = new EventEmitter<string>();

  imageFile: { url: string, name: string, isReady: boolean } = {
    url: null,
    name: 'image.png',
    isReady: false
  };

  constructor(private _DomSanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.imageFile.url = this.logoUrl;
  }

  save() { }

  selectImageEvent($event: Event) {
    const files = ($event.target as any).files;

    if (files) {
      this.imageFile.isReady = false;
      setTimeout(() => {
        const file = files[0];
        this.imageFile.name = file.name;
        const windowUrl = (window.URL || (window as any).webkitURL);
        let downloadUrl = windowUrl.createObjectURL(file);
        downloadUrl = this._DomSanitizer.bypassSecurityTrustUrl(downloadUrl);
        this.imageFile.url = downloadUrl;
        this.imageFile.isReady = true;
        this.change.emit(file);
      });
    }
  }
}
