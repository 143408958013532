import { FilterDataType } from './_filter-data.type';
import { SortType } from './sort.type';

export class FilterType {
    offset: number;
    limit: number;
    logic: string;
    filter: Array<FilterDataType>;
    sort: Array<SortType>;
    status: number;
    statusArray: Array<any>;
    categoryArray: Array<any>;
    language: string;
    isRecurrent: boolean;
    eventId: number;
    companyId: number;
    campaignId: number;
    generated: boolean;
    typeIcg: number;
    idProduct: number;
    idProductSize: number;
    rateOption: number;

    constructor() {
        this.clearFilter();
    }

    clearFilter() {
        this.offset = 0;
        this.limit = 5;
        // this.filter = new FilterDataType();
    }
}
