import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecordAction } from '../../enums';


export interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('options') options: any;
  // tslint:disable-next-line: no-input-rename
  @Input('selectedOptions') selectedOptions: any;

  @Input() actionInput: RecordAction;
  items: any = [];
  roleChip: any;

  @Output() tagAddItem = new EventEmitter();
  @Output() tagDeleteItem = new EventEmitter();

  constructor() { }

  ngOnInit() {

    if (this.actionInput === RecordAction.Update) {
      for (const rol of this.selectedOptions) {
        this.items.push({ display: rol.name, value: rol.roleId });
      }
    }
  }

  onAdd(item: any) {
    this.tagAddItem.emit(item);
  }

  onRemove(item: any) {
    this.tagDeleteItem.emit(item);
  }
}
