import { Injectable } from '@angular/core';
import { PRODUCTGALLERY_ENDPOINTS } from '../products.endpoints';
import { Observable } from 'rxjs/Observable';
import { ProductGallery } from '../models/productGallery.type';
import { ProductGalleryMapper } from '../mappers/productGallery.mapper';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../../../shared/services/base.api.service';
import { Response } from 'app/shared/models/response';

@Injectable()
export class ProductGalleryService extends BaseApiService<ProductGallery> {
    model = ProductGallery;
    mapper: ProductGalleryMapper = new ProductGalleryMapper();
    apiEndPoint: any = PRODUCTGALLERY_ENDPOINTS;

    // Related Products

    getImageList(id: number): Observable<Array<ProductGallery>> {
        return this.http
            .get(
                this.apiEndPoint.list.replace('{id}', id.toString()) +
                    '?random=' +
                    this.makeRandomString(15)
            )
            .pipe(
                map((res) => {
                    return this.mapper.mapList(res, this.model);
                })
            );
    }

    setProfileAvatar(id: number, image: string, sku: string) {
        return this.http
            .post(this.apiEndPoint.avatar.replace('{id}', id.toString()), {
                image: image,
                sku: sku,
            })
            .pipe(
                map((res) => {
                    return res;
                    // return this.sharedMapper.mapPagedApiResponse<ProductGallery>(res, this.mapper.mapList, this.model).list;
                })
            );
    }

    deleteImage(id: number, imageId: number): Observable<Response> {
        return this.http.delete<Response>(
            this.apiEndPoint.image
                .replace('{id}', id.toString())
                .replace('{imageId}', imageId.toString())
        );
    }

    create(data: any): Observable<boolean> {
        return this.http
            .post<boolean>(
                this.apiEndPoint.item.replace(
                    '{id}',
                    data.productId.toString()
                ),
                data
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    private makeRandomString(length: number): string {
        let text = '';
        const possible =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(
                Math.floor(Math.random() * possible.length)
            );
        }
        return text;
    }
}
