import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'module-toolbar',
  templateUrl: './module-toolbar.component.html',
  styleUrls: ['./module-toolbar.component.scss']
})
export class ModuleToolbarComponent implements OnInit {

  @Input() title: string;
  @Input() defaultIcon: string;
  @Input() customImage = false;
  @Input() showBreadcrumbs = true;
  @Input() subTitle: string;
  constructor() { }

  ngOnInit() {
    if (this.title == null) {
      throw new Error('Module Title is required');
    }
  }

}
