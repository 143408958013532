import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { GLinkDirectivesModule } from 'app/core/directives/directives';
import { GLinkPipesModule } from 'app/core/pipes/pipes.module';

import { GLINK_CONFIG, GLinkConfigService } from 'app/core/services/config.service';
import { GLinkCopierService } from 'app/core/services/copier.service';
import { GLinkMatchMediaService } from 'app/core/services/match-media.service';
import { GLinkMatSidenavHelperService } from 'app/core/directives/glink-mat-sidenav/glink-mat-sidenav.service';
import { GLinkNavigationService } from 'app/core/components/navigation/navigation.service';
import { GLinkSidebarService } from 'app/core/components/sidebar/sidebar.service';
import { GLinkSplashScreenService } from 'app/core/services/splash-screen.service';
import { GLinkTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { GLinkExcelService } from 'app/core/services/excel.service';
import { NavbarModule } from './layout/components/navbar/navbar.module';
import { PagesModule } from './pages/pages.module';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from './services/authentication.service';
import { SecurityMapper } from './mappers/security.mapper';



@NgModule({
    entryComponents: [],
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        GLinkDirectivesModule,
        GLinkPipesModule,
        NavbarModule,
        PagesModule

    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

      

        FlexLayoutModule,

        GLinkDirectivesModule,
        GLinkPipesModule,

        NavbarModule,
        PagesModule
    ],
    providers      : [
        GLinkConfigService,
        GLinkCopierService,
        GLinkMatchMediaService,
        GLinkMatSidenavHelperService,
        GLinkNavigationService,
        GLinkSidebarService,
        GLinkSplashScreenService,
        GLinkTranslationLoaderService,
        GLinkExcelService,
        
        SecurityMapper,
        AuthenticationService,
        JwtHelperService
        
    ]
})
export class CoreModule
{
    constructor(@Optional() @SkipSelf() parentModule: CoreModule)
    {
        if ( parentModule )
        {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders
    {
        return {
            ngModule : CoreModule,
            providers: [
                {
                    provide : GLINK_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
