import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GLinkConfigService } from 'app/core/services/config.service';
import { glinkAnimations } from 'app/core/animations';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { CredentialsType } from 'app/core/models/credentials.type';
import { ObservableDataService } from 'app/core/services/observable-data.service';

import { environment as env } from '../../../../../environments/environment';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: glinkAnimations,
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginFormErrors: any;
    isWaiting = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {GLinkConfigService} _glinkConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private _glinkConfigService: GLinkConfigService,
        private _formBuilder: FormBuilder,
        private ods: ObservableDataService
    ) {
        // Configure the layout
        this._glinkConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
            },
        };

        // Set the defaults
        this.loginFormErrors = {
            username: {},
            password: {},
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['webhome']);
        }

        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
        });

        this.loginForm.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.onLoginFormValuesChanged();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    performLogin() {
        if (this.loginForm.invalid) {
            return;
        }

        this.isWaiting = true;

        this.authService.login(this.getCredentials()).subscribe((res) => {
            if (!res) {
                this.isWaiting = false;
                return;
            }

            if (res.length === 0) {
                this.isWaiting = false;
                return;
            }
            // Get the current user information.
            this.authService.getCurrentUserInformation().subscribe((info) => {
                if (!info) {
                    this.isWaiting = false;
                    return;
                }

                //     // Fill the additional data to UserSessionType.
                res.avatarUrl = info.avatarUrl;
                if (!info.lastName) {
                    info.lastName = ' ';
                }
                res.fullName = info.firstName + ' ' + info.lastName;
                res.userId = info.userId;

                //     res.accountId = info.accountId;
                //     res.employeeId = info.employeeId;
                //     res.rolesNames = info.rolesNames;
                //     res.userId = info.userId;
                this.authService
                    .getCompanyProfile()
                    .subscribe((infoCompany) => {
                        //     // Update the user session information in the Local Storage.

                        if (!infoCompany) {
                            this.isWaiting = false;
                            return;
                        }

                        res.companyName = infoCompany.name;
                        res.companyLogo = infoCompany.logoUrl;

                        localStorage.removeItem(env.accessTokenLocalStorageKey);
                        localStorage.setItem(
                            env.accessTokenLocalStorageKey,
                            JSON.stringify(res)
                        );

                        //     // Notify to subscribers that the user session information is available.
                        this.ods.setloggedUser(res);

                        //     // Redirect to home.
                        //     this.router.navigate(['/']);

                        this.isWaiting = false;
                        this.router.navigate(['/webhome']);
                    });
            });
        });
    }

    getCredentials(): CredentialsType {
        const credentials = new CredentialsType();
        credentials.setUserNamePasswordValues(
            this.loginForm.get('username').value,
            this.loginForm.get('password').value
        );
        return credentials;
    }
}
