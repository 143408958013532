import { NgModule } from '@angular/core';
import { MatDividerModule, MatListModule, MatSlideToggleModule } from '@angular/material';

 

import { QuickPanelComponent } from 'app/core/layout/components/quick-panel/quick-panel.component';
import { CoreSharedModule } from 'app/core/coreshared.module';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports     : [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        CoreSharedModule
      
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
