import { environment as env } from '../../../environments/environment';

export class RestorePassword {
    email: string;
    domain: string;
   

    constructor() {
        const url =  'snc.uplinkbusiness.com';
        this.domain = url;
    }

    setEmailValue(email) {
        this.email = email;
    }

}
