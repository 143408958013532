import { Component, OnInit, Inject } from '@angular/core';
import { RecordAction } from '../../enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FieldError } from '../../models/field-error';

@Component({
  selector: 'wtf-errors-list-modal',
  templateUrl: './errors-list-modal.component.html',
  styleUrls: ['./errors-list-modal.component.scss']
})
export class ErrorsListModalComponent implements OnInit {
  dialogTitle: string;
  errors: Array<FieldError>;

  constructor(public dialogRef: MatDialogRef<ErrorsListModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {

    this.dialogTitle = data.dialogTitle;
    this.errors = data.errors;
    this.setIcons();
  }

  // tslint:disable-next-line:typedef
  setIcons() {
    if (!this.errors) {
      return;
    }

    this.errors.forEach(error => {
      switch (error.validationName) {
        case 'required': {
          error['icon'] = 'check_circle';
          break;
        }
        case 'email': {
          error['icon'] = 'email';
          break;
        }
        case 'maxlength': {
          error['icon'] = 'trending_up';
          break;
        }
        case 'minlength': {
          error['icon'] = 'trending_down';
          break;
        }
        default: {
          error['icon'] = 'error';
        }
      }
    });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {

  }
}
