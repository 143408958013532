import { environment as env } from '../../../environments/environment';

export const MYCOMPANY_ENDPOINTS = {
  item: `${env.UpLink_ApiUrl}api/profilemanager/mycompany`,
  save: `${env.UpLink_ApiUrl}api/profilemanager/mycompany`,
  uploadFile: `${env.UpLink_ApiUrl}api/filemanager/file/upload`,
  shop: `${env.UpLink_ApiUrl}api/geolocation/shop/{id}`,
  shopBusinessHours: `${env.UpLink_ApiUrl}api/geolocation/shop/{id}/{dayOfWeek}`
};

export const CONTACT_ENDPOINTS = {
  list: `${env.UpLink_ApiUrl}api/profilemanager/mycompany/contacts`,
  item: `${env.UpLink_ApiUrl}api/profilemanager/mycompany/contact/{id}`,
  base: `${env.UpLink_ApiUrl}api/profilemanager/mycompany/contact`,
};
