import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BaseApiService } from './services/base.api.service';
import { BaseMapper } from './mappers/base.mapper';
import { SharedMapper } from './mappers/shared.mapper';
import { CdkTableModule } from '@angular/cdk/table';
import {
    MatTableModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
} from '@angular/material';

import { DateFormatPipe } from './pipes/dateFormat';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ErrorsIndicatorComponent } from './components/errors-indicator/errors-indicator.component';
import { ErrorsListModalComponent } from './components/errors-list-modal/errors-list-modal.component';
import { BreadCrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ModuleToolbarComponent } from './components/module-toolbar/module-toolbar.component';
import { RouterModule } from '@angular/router';
import { CoreSharedModule } from 'app/core/coreshared.module';
import { DialogContentComponent } from './components/dialog-content/dialog-content.component';
import { UploadComponent } from './components/upload/upload.component';
import { UploadExcelComponent } from './components/upload-excel/upload-excel.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SpinnerButtonDirective } from './directive/spinner-button';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { FilterPanelTopComponent } from './components/filter-panel-top/filter-panel-top.component';
import { HasPermissionDirective } from './directive/has-permission';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { TagInputModule } from 'ngx-chips';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatTableModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        NgxDatatableModule,
        RouterModule,
        MatTableModule,
        MatButtonModule,
        FileUploadModule,
        CoreSharedModule,
        TagInputModule,
    ],
    declarations: [
        DateFormatPipe,
        ErrorsIndicatorComponent,
        ErrorsListModalComponent,
        ModuleToolbarComponent,
        BreadCrumbsComponent,
        DialogContentComponent,
        UploadComponent,
        SpinnerButtonDirective,
        ImageUploadComponent,
        UploadExcelComponent,
        FilterPanelComponent,
        FilterPanelTopComponent,
        HasPermissionDirective,
        TagInputComponent,
        TagsInputComponent,
    ],
    providers: [BaseApiService, BaseMapper, SharedMapper],
    exports: [
        DateFormatPipe,
        ErrorsIndicatorComponent,
        ErrorsListModalComponent,
        BreadCrumbsComponent,
        ModuleToolbarComponent,
        DialogContentComponent,
        UploadComponent,
        SpinnerButtonDirective,
        ImageUploadComponent,
        UploadExcelComponent,
        FilterPanelComponent,
        FilterPanelTopComponent,
        HasPermissionDirective,
        TagInputComponent,
        TagsInputComponent,
    ],
    entryComponents: [ErrorsIndicatorComponent, ErrorsListModalComponent],
})
export class SharedModule {}
