import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule, MatRippleModule } from '@angular/material';

import { TranslateModule } from '@ngx-translate/core';

import { GLinkNavigationComponent } from './navigation.component';
import { GLinkNavVerticalItemComponent } from './vertical/item/item.component';
import { GLinkNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { GLinkNavVerticalGroupComponent } from './vertical/group/group.component';
import { GLinkNavHorizontalItemComponent } from './horizontal/item/item.component';
import { GLinkNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslateModule.forChild()
    ],
    exports     : [
        GLinkNavigationComponent
    ],
    declarations: [
        GLinkNavigationComponent,
        GLinkNavVerticalGroupComponent,
        GLinkNavVerticalItemComponent,
        GLinkNavVerticalCollapsableComponent,
        GLinkNavHorizontalItemComponent,
        GLinkNavHorizontalCollapsableComponent
    ]
})
export class GLinkNavigationModule
{
}
