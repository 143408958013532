import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material';

 

import { MailConfirmComponent } from 'app/core/pages/authentication/mail-confirm/mail-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes = [
    {
        path     : 'auth/mail-confirm',
        component: MailConfirmComponent
    }
];

@NgModule({
    declarations: [
        MailConfirmComponent

    ],
    imports     : [
        RouterModule.forChild(routes),
 FormsModule,
        ReactiveFormsModule,
        MatIconModule,

         
    ]
})
export class MailConfirmModule
{
}
