export class UserSessionType {
    userName: string;
    fullName: string;
    avatarUrl: string;
    token: string;
    tokenType: string;
    refreshToken: string;
    isOwner: boolean;
    timeZoneInfo: string;
    rolesNames: Array<string>;
    tenantId: number;
    userId: number;
    accountId: number;
    employeeId: number;
    companyIsSaaSOwner: boolean;
    companyId: number;
    companyEmail: string;
    companyName: string;
    companyLogo: string;
    companyModules: Array<any>;
    reportsByUser: Array<any>;
    params: string;
    isParentCompany: boolean;
    userModules: any[];
}
