import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ErrorsListModalComponent } from '../errors-list-modal/errors-list-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'wtf-errors-indicator',
  templateUrl: './errors-indicator.component.html',
  styleUrls: ['./errors-indicator.component.scss']
})
export class ErrorsIndicatorComponent implements OnInit, OnDestroy {
  @Input() errors: Array<string>;
  dialogRef: any;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  showErrors() {
    if(!this.errors || this.errors.length === 0) {
      return;
    }

    this.dialogRef = this.dialog.open(ErrorsListModalComponent, {
      panelClass: 'errors-form-dialog',
      data: {
          dialogTitle: `Errors for the field.`,
          errors: this.errors
      }
  });
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
        this.dialogRef.close();
    }
}
}
