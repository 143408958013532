import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { Contact } from '../../models/contact.type';
import { ContactService } from '../../services/contact.service';
import { RecordAction } from 'app/shared/enums';
import { CategoriesService } from 'app/features/products/categories/services/categories.service';
import { Category } from 'app/features/products/categories/models/category.type';
import { glinkAnimations } from 'app/core/animations';
import * as toastr from 'toastr';
@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    animations: glinkAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [ContactService, CategoriesService],
})
export class ContactFormComponent implements OnInit {
    contact: Contact = new Contact();
    dialogTitle: string;
    contactForm: FormGroup;
    action: RecordAction;
    callback: (contact) => void;
    numString: string;
    numArray = new Array();
    cont: number;
    contNum: number;
    recordAction = RecordAction;
    form: FormGroup;

    allRoles: Array<Category>;
    service: CategoriesService;

    constructor(
        public dialogRef: MatDialogRef<ContactFormComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private contactService: ContactService,
        private _service: CategoriesService
    ) {
        this.service = _service;
        this.service.getComboList().subscribe((roles) => {
            this.allRoles = roles;
        });

        this.action = data.action;
        this.callback = data.callback;

        if (
            this.action === RecordAction.Update ||
            this.action === RecordAction.View
        ) {
            this.contact = data.contact;
            this.dialogTitle = this.contact.name;
        } else {
            this.dialogTitle = 'Nuevo Contacto';
        }

        this.contactForm = this.createForm();
    }

    ngOnInit() {}

    createForm() {
        this.form = new FormGroup({
            name: new FormControl(this.contact.name),
            role: new FormControl(this.contact.role),
            phoneNumber: new FormControl(this.contact.phoneNumber),
            email: new FormControl(this.contact.email),
        });

        const form = this.form;

        form.controls['name'].valueChanges.subscribe(
            (value) => (this.contact.name = value)
        );
        form.controls['role'].valueChanges.subscribe(
            (value) => (this.contact.role = value)
        );
        form.controls['phoneNumber'].valueChanges.subscribe(
            (value) => (this.contact.phoneNumber = value)
        );
        form.controls['email'].valueChanges.subscribe(
            (value) => (this.contact.email = value)
        );
        return form;
    }

    saveContact() {
        if (this.contactForm.invalid) {
            return;
        }
        this.numString = this.contact.email;
        if (this.numString.indexOf('@') < 0) {
            toastr.warning('Formato email incorrecto.', 'Contacto');
            return;
        }

        this.numString = this.contact.phoneNumber;
        this.numArray = this.numString.match(/.{1,3}/g);
        this.cont = 0;
        this.contNum = 0;
        this.numArray.forEach((element) => {
            if (this.cont === 0) {
                if (element === '593') {
                    this.callback(this.contact);
                } else {
                    this.contNum = 1;
                }
            }
            this.cont = 1;
        });
        if (this.contNum === 1) {
            toastr.warning('Número incorrecto.', 'Contacto');
        }

        return;
    }
}
