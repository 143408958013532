import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



import { navigation } from 'app/app.navigation';
import { GLinkConfigService } from 'app/core/services/config.service';
import { GLinkNavigationService } from 'app/core/components/navigation/navigation.service';
import { GLinkSidebarService } from 'app/core/components/sidebar/sidebar.service';
import { GLinkSplashScreenService } from 'app/core/services/splash-screen.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { Router } from '@angular/router';



@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    navigation: any;
    glinkConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {GLinkConfigService} _glinkConfigService
     * @param {GLinkNavigationService} _glinkNavigationService
     * @param {GLinkSidebarService} _glinkSidebarService
     * @param {GLinkSplashScreenService} _glinkSplashScreenService
     * @param {GLinkTranslationLoaderService} _glinkTranslationLoaderService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _glinkConfigService: GLinkConfigService,
        private _glinkNavigationService: GLinkNavigationService,
        private _glinkSidebarService: GLinkSidebarService,
        private _glinkSplashScreenService: GLinkSplashScreenService,
        private authService: AuthenticationService,
        private router: Router
        

      /*  private _glinkTranslationLoaderService: GLinkTranslationLoaderService,
        private _translateService: TranslateService */
    )
    {

        if (location.href.includes('dinersclub') || location.href.includes('sin-fronteras') || location.href.includes('pacari')) {
            if (location.protocol === 'http:') {
             window.location.href = location.href.replace('http', 'https');
            }
           }  
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._glinkNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._glinkNavigationService.setCurrentNavigation('main');

        // Add languages
        // this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        // this._translateService.setDefaultLang('en');

        // Set the navigation translations
       // this._glinkTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        // this._translateService.use('en');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

     /*   
        if (authService.isLoggedIn()) {
            router.navigate(['webhome']);
        }*/
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._glinkConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.glinkConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._glinkSidebarService.getSidebar(key).toggleOpen();
    }
}
