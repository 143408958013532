import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ObservableDataService } from 'app/core/services/observable-data.service';
import * as toastr from 'toastr';
import { AuthenticationService } from './../../core/services/authentication.service';

@Injectable()
export class DefaultReportGuard implements CanActivate {
    constructor(
        private router: Router,
        private ods: ObservableDataService,
        private jwt: JwtHelperService,
        private auth: AuthenticationService
    ) {}

    canActivate() {
        const sessionInfo = this.ods.getLoggedUser();
        setTimeout(() => {
            let urlRprt =
                sessionInfo.reportsByUser[0] &&
                sessionInfo.reportsByUser[0].urlReport
                    ? sessionInfo.reportsByUser[0].urlReport
                    : '';
            this.router.navigate([urlRprt]);
        }, 100);

        return false;
    }
}
