import { Component, HostBinding, Input } from '@angular/core';

import { GLinkNavigationItem } from 'app/core/types';

@Component({
    selector   : 'glink-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class GLinkNavVerticalItemComponent
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: GLinkNavigationItem;

    /**
     * Constructor
     */
    constructor()
    {
    }
}
