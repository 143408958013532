import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as QueryString from 'query-string';
import * as jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import * as toastr from 'toastr';

import { UserSessionType } from '../../core/models/user-session.type';
import { CredentialsType } from '../models/credentials.type';
import { SecurityMapper } from '../mappers/security.mapper';

import { environment as env } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { ICurrentUser } from 'app/core/models/icurrent-user.interface';
import { Observable } from 'rxjs/Observable';
import { ObservableDataService } from 'app/core/services/observable-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from 'rxjs';
import { RestorePassword } from '../models/restore-password.type';
import { BinDatasource } from 'app/features/paymentGateway/bank-card/datasources/bin.datasource';

@Injectable()
export class AuthenticationService {
    loggedUserInfo: UserSessionType;

    // Constructor.
    constructor(
        private http: HttpClient,
        private securityMapper: SecurityMapper,
        private ods: ObservableDataService,
        private jwt: JwtHelperService
    ) {}

    // Perform login method.  Observable<UserSessionType>
    login(credentials: CredentialsType): Observable<any> {
        const body =
            this.securityMapper.mapCredentialsToOauthFormat(credentials);

        this.logout();

        // TODO: Change UpLink_ApiUrl by UpLink_ApiUrl when merge v2 branch into develop/master
        return this.http
            .post(env.UpLink_ApiUrl + 'api/security/auth/login', body, {
                headers: new HttpHeaders({
                    apiKey: env.apiKey,
                }),
                responseType: 'json',
            })
            .pipe(
                map((res) => {
                    return this.saveToken(res);
                }),
                catchError((error) => {
                    toastr.error(error.error.message);
                    return of([]);
                })
            );
    }

    // Get the current user information.
    getCurrentUserInformation(): Observable<ICurrentUser> {
        const info = localStorage.getItem(env.accessTokenLocalStorageKey);

        return this.http.get<ICurrentUser>(
            env.UpLink_ApiUrl + 'api/profilemanager/user',
            {
                headers: new HttpHeaders({
                    apiKey: env.apiKey,
                    Authorization: 'Bearer ' + JSON.parse(info).token,
                }),
            }
        );
    }

    getCompanyProfile(): Observable<any> {
        const info = localStorage.getItem(env.accessTokenLocalStorageKey);

        return this.http.get<ICurrentUser>(
            env.UpLink_ApiUrl + 'api/profilemanager/mycompany',
            {
                headers: new HttpHeaders({
                    apiKey: env.apiKey,
                    Authorization: 'Bearer ' + JSON.parse(info).token,
                }),
            }
        );
    }

    // Get the current user information.
    getToken(): Observable<any> {
        const info = localStorage.getItem(env.accessTokenLocalStorageKey);

        if (!info) {
            return null;
        } else {
            return JSON.parse(info).token;
        }
    }

    logout() {
        this.loggedUserInfo = null;
        localStorage.removeItem(env.accessTokenLocalStorageKey);
    }
    // private saveToken(tokenData: any): UserSessionType
    private saveToken(tokenData: any) {
        const tokenDecoded = jwt_decode(tokenData.token);

        if (!tokenDecoded.isCustomer && tokenDecoded.isCustomer !== undefined) {
            const info: UserSessionType = new UserSessionType();
            info.token = tokenData.token;

            info.isOwner = tokenDecoded.userIsOwner;
            info.companyId = tokenDecoded.companyId;
            info.companyEmail = tokenDecoded.companyEmail;
            info.companyIsSaaSOwner = tokenDecoded.companySaaSOwner;
            localStorage.setItem(
                env.accessTokenLocalStorageKey,
                JSON.stringify(info)
            );
            info.companyModules = tokenDecoded.companyModules;
            info.userModules = tokenDecoded.userModules;
            info.isParentCompany = tokenDecoded.isParentCompany;
            info.reportsByUser = tokenDecoded.reportsByUser;
            info.params = tokenDecoded.params;

            this.loggedUserInfo = info;
            return this.loggedUserInfo;
        }

        localStorage.removeItem(env.accessTokenLocalStorageKey);

        toastr.error('Usuario no valido para esta aplicación');
        return null;
    }

    isLoggedIn() {
        const sessionInfo = this.ods.getLoggedUser();

        if (sessionInfo.token) {
            if (!this.jwt.isTokenExpired(sessionInfo.token)) {
                return true;
            }
        }

        return false;
    }
    // Perform login method.  Observable<UserSessionType>
    resetPassword(data: RestorePassword): Observable<any> {
        return this.http
            .post(
                env.UpLink_ApiUrl + 'api/security/auth/password/restore',
                data,
                {
                    headers: new HttpHeaders({
                        apiKey: env.apiKey,
                    }),
                    responseType: 'json',
                }
            )
            .pipe(
                map((res) => {
                    return res;
                }),
                catchError((error) => {
                    toastr.error(error.error.message);
                    return of([]);
                })
            );
    }
}
