import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';

import { GLinkConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [
        GLinkConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        GLinkConfirmDialogComponent
    ],
})
export class GLinkConfirmDialogModule
{
}
