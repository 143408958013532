import { NgModule } from '@angular/core';

import { GLinkWidgetComponent } from './widget.component';
import { GLinkWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        GLinkWidgetComponent,
        GLinkWidgetToggleDirective
    ],
    exports     : [
        GLinkWidgetComponent,
        GLinkWidgetToggleDirective
    ],
})
export class GLinkWidgetModule
{
}
