import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { ObservableDataService } from 'app/core/services/observable-data.service';
import * as toastr from 'toastr';
import { Observable } from 'rxjs';

@Injectable()
export class ModulePermissionGuard implements CanActivate {
    defaultAction = 'list';

    constructor(private router: Router, private ods: ObservableDataService) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const parentRoute = route.parent.routeConfig['_loadedConfig'];
        const hostModule = parentRoute.module.instance;
        const moduleName = hostModule.moduleName;

        const currentUser = this.ods.getLoggedUser();
        if (currentUser) {
            const { userModules } = currentUser;

            if ('data' in route) {
                const routeData = route.data;

                const actionList =
                    'action' in routeData
                        ? routeData.action
                        : this.defaultAction;
                const actions = actionList.split(',');
                let permitted = false;
                actions.forEach((action) => {
                    if (userModules[moduleName]) {
                        if (userModules[moduleName][action]) permitted = true;
                    }
                });
                return permitted;
            }
        }

        toastr.warning('No puede acceder a esta página.', 'Solicitud Denegada');
        this.router.navigate(['/']);
        return false;
    }
}
