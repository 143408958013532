import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { Shipping } from '../models/shipping';
import { ShippingMapper } from '../mappers/shipping.mapper';
import { SHIPPING_ENDPOINTS, SHIPPING_CARRIER_ENDPOINTS } from '../shipping-orders.endpoints';
import { map } from 'rxjs/operators';
import { PagedApiResponse } from 'app/shared/models/paged-api-response.type';
import { Observable } from 'rxjs';
import { FilterType } from 'app/shared/models/filter.type';
import { ShippingCarrier } from '../models/shipping-carrier';
import { ShippingCarrierMapper } from '../mappers/shipping-carrier.mapper';

@Injectable()
export class ShippingService extends BaseApiService<Shipping>  {
  model = Shipping;
  mapper = new ShippingMapper();
  carrierMapper = new ShippingCarrierMapper();
  apiEndPoint: any = SHIPPING_ENDPOINTS;
  apiCarrierEndPoint: any = SHIPPING_CARRIER_ENDPOINTS;

  getList(filter?: FilterType, id?: number): Observable<PagedApiResponse<Shipping>> {
    return this.http.get(this.apiEndPoint.list.replace('{id}', id.toString())).pipe(map(res => {
      return this.sharedMapper.mapPagedApiResponse(res, this.mapper.mapList, this.model);
    }));
  }

  getCarriersOptions(): Observable<Array<ShippingCarrier>> {
    return this.http.get(this.apiCarrierEndPoint.options).pipe(map(res => {
      return this.carrierMapper.mapList(res, ShippingCarrier);
    }));
  }
}
