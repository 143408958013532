import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';

import { GLinkNavigationModule } from 'app/core/components';
 

import { NavbarComponent } from 'app/core/layout/components/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { CoreSharedModule } from '../../../coreshared.module';


@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        CommonModule,

 CoreSharedModule,


      
        GLinkNavigationModule
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}
