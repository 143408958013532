import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { RecordAction } from 'app/shared/enums';
import { ShippingOrderService } from '../../../services/shipping-order.service';
import { ShippingOrder } from '../../../models/shipping-order.type';
import { SHIPPING_STATUSES } from '../../../../shipping-catalog';
import { ShippingCarrier } from '../../../models/shipping-carrier';
import { ShippingService } from '../../../services/shipping.service';

@Component({
  selector: 'shipping-form',
  templateUrl: './shipping-form.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    ShippingOrderService,
    ShippingService
  ]
})
export class ShippingOrderFormComponent implements OnInit {

  STATUS = SHIPPING_STATUSES;
  shippingOrderForm: FormGroup;
  shippingOrder: ShippingOrder = new ShippingOrder();

  dialogTitle: string;
  action: RecordAction;
  recordAction = RecordAction;
  noteOptions: any;
  activeNoteAditional = false;
  carriers: any;
  actionForm: boolean; 
  shippingEstimatedDateValue =  new Date();
  shippingCarrierId: number;
  trackingCode: string;
  notes: any;
  noteAditional: any;
  contador_semana = 0;
  minDate = new Date();
  maxDate = new Date();
  setShippingDate: boolean;

  callback: (data) => void;

  constructor(public dialogRef: MatDialogRef<ShippingOrderFormComponent>,
    @Inject(MAT_DIALOG_DATA)  data: any, private shippingService: ShippingService) {
    this.action = data.action;
    this.setShippingDate = data.setShippingDate;
    this.noteOptions = data.noteOptions;
    this.callback = data.callback;
    this.actionForm = true;
      if (data.activo === 2)
      {
        this.actionForm = false;
      }
       
     
    if (this.action === RecordAction.Update) {
      this.shippingOrder = data.shippingOrder;
      this.dialogTitle = this.shippingOrder.description;
    }

    this.shippingOrderForm = new FormGroup({
      shippingEstimatedDateValue: new FormControl(this.minDate),
      shippingCarrierId: new FormControl(),
      trackingCode: new FormControl(),
      notes: new FormControl(),
      noteAditional: new FormControl(),
      optionsNotes: new FormControl()
    });

    this.shippingOrderForm.controls['shippingEstimatedDateValue'].valueChanges.subscribe((value) => { this.shippingEstimatedDateValue = value; });
    this.shippingOrderForm.controls['shippingCarrierId'].valueChanges.subscribe((value) => { this.shippingCarrierId = value.id; });
    this.shippingOrderForm.controls['trackingCode'].valueChanges.subscribe((value) => { this.trackingCode = value; });
    this.shippingOrderForm.controls['notes'].valueChanges.subscribe((value) => { this.notes = value; });
    this.shippingOrderForm.controls['noteAditional'].valueChanges.subscribe((value) => { this.noteAditional = value; });
    this.shippingOrderForm.controls['optionsNotes'].valueChanges.subscribe((value) => { 
      this.notes = value; 
      if (value==="No se puede contactar con el cliente.")
      {
        this.activeNoteAditional = true;
      }
      else
      {
        this.activeNoteAditional = false;
      } 
    });
  }

  displayFnCarrier(carrier?: ShippingCarrier): string | undefined {
    return carrier ? carrier.name : undefined;
  }

  ngOnInit() {
    const date = new Date();
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() + 1);
    this.maxDate = date;

    this.shippingService.getCarriersOptions().subscribe(res => {
      this.carriers = res;
    });
  }

  saveShippingOrder() {
    if (this.shippingOrderForm.invalid) {
      return;
    }

    const data = {
      shippingEstimatedDate: this.shippingEstimatedDateValue,
      shippingCarrierId: this.shippingCarrierId,
      trackingCode: this.trackingCode,
      notes: this.notes,
      noteAditional: this.noteAditional,
    };
  
    this.callback(data);
  }
}
