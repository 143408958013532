import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FileUploader, Headers as uploadHeader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment as env } from '../../../../environments/environment';


// const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

const URL = env.UpLink_ApiUrl + 'api/filemanager/file/upload';
const URL_RAW = env.UpLink_ApiUrl + 'api/filemanager/file/upload_raw';



@Component({
  selector: 'glink-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: []
})
export class UploadComponent implements OnInit   {


    public uploader: FileUploader;
    public hasBaseDropZoneOver = false;
    public hasAnotherDropZoneOver = false;
    public headers: UploadHeader[];
    public header: UploadHeader;
    @Input() path: string;
    @Input() isRawInput: boolean = false;
    @Output() imageUploaded = new EventEmitter<any>();
    @Output() uploadCompleteAll = new EventEmitter<any>();
    isWaiting = false;

    constructor(){

    }

    ngOnInit(): void {
      this.header = new UploadHeader();
      this.header.name = 'apiKey';
      this.header.value = env.apiKey;
        
      this.headers = [];
        this.headers.push( this.header );
      //  this.path = '/6/products/9/gallery/';
        this.uploader = new FileUploader({
          url: (this.isRawInput ? URL_RAW : URL) , headers: this.headers, additionalParameter: {
            path: this.path}}
            );

        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
        };

        this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    
        this.uploader.onCompleteAll = () => {
            this.isWaiting = false;
            this.uploadCompleteAll.emit('Complete');
          };

    }

    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        const data = JSON.parse(response); // success server response
        this.imageUploaded.emit(data.file);
       
    }

    onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        const error = JSON.parse(response); // error server response
    }
    public fileOverBase(e: any): void {
      this.hasBaseDropZoneOver = e;
    }

    upload(){ 
        this.isWaiting = true;
        this.uploader.uploadAll();
    }

}

 class UploadHeader implements uploadHeader {
     name: string;
     value: string;

     constructor(){}
 }
