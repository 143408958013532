import { ModelBase } from '../../../../shared/models/model.base';

export class Shipping extends ModelBase {
  shippingOrderId: number;
  shippingCarrierId: number;
  trackingCode: '';
  fee: '';
  createdAt: Date = null;

  constructor() {
    super();
  }
}
