import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SALES_ANALYTICS_ENDPOINTS} from '../sales.endpoints';
import { map, filter, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

@Injectable()
export class SalesAnalyticsService {
  
  apiEndPoint: any = SALES_ANALYTICS_ENDPOINTS;

  // Constructor.
  constructor (
    private http: HttpClient
  ) {}

  /**
   * Listar las ventas diarias indexadas en ElasticSearch desglosadas por hora
   * @param companyId ID de la compañia que se está consultando.
   */
  getListSalesDaily(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.daily, { "companyId": companyId })
    .pipe( map( (res: Response) => res), 
      catchError ( (res: Response) =>  this.onError(res) ));
  }
 
  /**
   * Listar las ventas semanales indexadas en ElasticSearch desglosadas por días
   * @param companyId ID de la compañia que se está consultando.
   */
  getListSalesWeekly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.weekly, { "companyId": companyId })
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Listar las ventas mensual indexadas en ElasticSearch desglosadas por días
   * @param companyId ID de la compañia que se está consultando.
   */
  getListSalesMonthly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.monthly, {"companyId": companyId})
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Listar las ventas trimestrales indexadas en ElasticSearch desglosadas por meses
   * @param companyId ID de la compañia que se está consultando.
   */
  getListSalesQuarterly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.quarterly, {"companyId": companyId})
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Crear un objeto que informará el error al consultar el EndPoint.
   * @param res 
   */
  onError(res: Response) {
    const error = {
      statusCode: res.status,
      statusMessage: 'Verificar el servicio ES esté activo y verificar Analytics EndPoints',
      error: res['message']

    };
    return throwError(error);
  }
}
