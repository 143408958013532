import { ModelBase } from '../../../../shared/models/model.base';

export class Category extends ModelBase {
    companyId: string;
    code: string;
    name: string;
    description: string;
    image: string;
    status: boolean;
    id: number;

    getTitle() {
        return `${this.name}`;
    }
}
