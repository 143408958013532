import { environment as env } from '../../../../environments/environment';

const BASE_URL_PRODUCTS = `${env.UpLink_ApiUrl}api/products`;
const BASE_URL_CATEGORIES = `${env.UpLink_ApiUrl}api/products/category`;
const BASE_RATE_PLANS = `${env.UpLink_ApiUrl}api/rateplanes`;

export const PRODUCT_ENDPOINTS = {
    list: `${BASE_URL_PRODUCTS}/list`,
    item: `${BASE_URL_PRODUCTS}/{id}`,
    itemBySku: `${BASE_URL_PRODUCTS}/{id}/{sku}`,
    status: `${BASE_URL_PRODUCTS}/status`,
    base: BASE_URL_PRODUCTS,
    activation: BASE_URL_PRODUCTS + '/{id}/activationToggle',
    uploadExcel: `${BASE_URL_PRODUCTS}/excel/upload/{id}`,
    uploadFile: `${env.UpLink_ApiUrl}api/filemanager/file/upload`,
    filter: `${BASE_URL_PRODUCTS}/list-filter`,
    productDemo: `${BASE_URL_PRODUCTS}/productdemo`,
    exportExcel: `${BASE_URL_PRODUCTS}/excel`,
};

export const CATEGORIES_ENDPOINTS = {
    list: `${BASE_URL_CATEGORIES}/list`,
};

export const PRODUCTGALLERY_ENDPOINTS = {
    item: BASE_URL_PRODUCTS + '/{id}/gallery',
    list: BASE_URL_PRODUCTS + '/{id}/gallery/list',
    avatar: BASE_URL_PRODUCTS + '/{id}/profile-picture',
    image: BASE_URL_PRODUCTS + '/{id}/gallery/{imageId}',
};

export const RATEPLANS_CATALOG_ENDPOINTS = {
    list: BASE_RATE_PLANS + '/catalog',
    product: BASE_RATE_PLANS + '/product/{id}',
    item: BASE_RATE_PLANS + '/{id}',
    base: BASE_RATE_PLANS,
    detail: `${BASE_RATE_PLANS}/detail`,
    listPriceRate: `${BASE_URL_PRODUCTS}/rate/{id}`,
};

export const ATTRIBUTE_ENDPOINT = {
    item: `${BASE_URL_PRODUCTS}/attribute/{productId}/{companyID}/`,
    attribute: `${BASE_URL_PRODUCTS}/attribute/`,
};

export const OFFER_ENDPOINT = {
    create: `${BASE_URL_PRODUCTS}/offers`,
    item: `${BASE_URL_PRODUCTS}/offers/{id}`,
    list: `${BASE_URL_PRODUCTS}/{id}/offers`,
};

export const PRODUCTFEATURES_ENDPOINT = {
    create: `${BASE_URL_PRODUCTS}/productFeatures/add`,
    item: `${BASE_URL_PRODUCTS}/productFeatures/{id}`,
    update: `${BASE_URL_PRODUCTS}/productFeatures/`,
    list: `${BASE_URL_PRODUCTS}/{id}/productFeatures/{companyId}`,
};

export const FEATURES_ENDPOINT = {
    create: `${BASE_URL_PRODUCTS}/features/add`,
    item: `${BASE_URL_PRODUCTS}/features/{id}`,
    list: `${BASE_URL_PRODUCTS}/{id}/features/list`,
    update: `${BASE_URL_PRODUCTS}/features/`,
};

export const MODIFIER_ENDPOINT = {
    getList: `${BASE_URL_PRODUCTS}/modifier/product`,
    getListModifier: `${BASE_URL_PRODUCTS}/modifier`,
    deleteModifier: `${BASE_URL_PRODUCTS}/modifier/{idProduct}/{idModifier}`,
    createModifier: `${BASE_URL_PRODUCTS}/modifier/create`,
    updateOrderModifier: `${BASE_URL_PRODUCTS}/modifiersproduct`,
};

export const PRODUCTSHOP_ENDPOINT = {
    getList: `${BASE_URL_PRODUCTS}/shop/status/list`,
    item: `${BASE_URL_PRODUCTS}/shop/status`,
};

export const RATE_ENDPOINT = {
    getList: `${BASE_URL_PRODUCTS}/rate`,
    createOrUpdatePriceRate: `${BASE_URL_PRODUCTS}/rate`,
    deletePriceRate: `${BASE_URL_PRODUCTS}/rate/{idRate}/{idProduct}`,
};

export const SIZE_ENDPOINT = {
    getList: `${BASE_URL_PRODUCTS}/size/WithoutRate/{idProduct}/{limit}/{offset}`,
    item: `${BASE_URL_PRODUCTS}/size/create`,
    assignRate: `${BASE_URL_PRODUCTS}/rate`,
    getListRate: `${BASE_URL_PRODUCTS}/size/{idProduct}/{limit}/{offset}`,
    deleteSize: `${BASE_URL_PRODUCTS}/size/{idProduct}/{idProductSize}/`,
    updateSimpleProduct: `${BASE_URL_PRODUCTS}/type`,
};
