import { Injectable } from '@angular/core';
import { CredentialsType } from '../models/credentials.type';

@Injectable()
export class SecurityMapper {

    mapCredentialsToOauthFormat(credentials: CredentialsType): any {
        const result = {};
        result['username'] = credentials.userName;
        result['password'] = credentials.password;
        result['domain'] = credentials.domain;
        result['client_id'] = credentials.clientId;
        result['refresh_token'] = credentials.refreshToken;

        return result;
    }
}
