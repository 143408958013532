import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../../shared/services/base.api.service';
import { CategoryMapper } from '../mappers/category.mapper';
import { Category } from '../models/category.type';
import { CategoryFlat } from '../models/category.flat';
import { Observable } from 'rxjs/Observable';
import { CATEGORIES_ENDPOINTS } from 'app/features/products/categories/categories.endpoints';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { SharedMapper } from 'app/shared/mappers/shared.mapper';
import { BaseMapper } from 'app/shared/mappers/base.mapper';
import { of } from 'rxjs';
import * as toastr from 'toastr';
@Injectable()
export class CategoriesService extends BaseApiService<Category> {
    model = Category;
    mapper = new CategoryMapper();
    apiEndPoint: any = CATEGORIES_ENDPOINTS;
    parentId: number;

    protected fileClient: HttpClient;
    constructor(
        http: HttpClient,
        public sharedMapper: SharedMapper,
        handler: HttpBackend
    ) {
        super(http, sharedMapper);
        this.fileClient = new HttpClient(handler);
    }
    updateStatus(id?: any, status?: any): Observable<any> {
        return this.http.post<any>(this.apiEndPoint.status, { id, status });
    }

    createCategory(filter?: any): Observable<CategoryFlat> {
        return this.http
            .post<CategoryFlat>(this.apiEndPoint.create, filter)
            .pipe(
                map(
                    (res) => {
                        return res;
                    },
                    catchError((error) => {
                        const errorText = error.error.message[0].message
                            ? error.error.message[0].message
                            : error.message;
                        toastr.error(errorText);
                        return of(CategoryFlat);
                    })
                )
            );
    }

    createCategoryPut(category: Category): Observable<boolean> {
        return this.http.put<boolean>(this.apiEndPoint.update, category).pipe(
            catchError((error) => {
                let errorText = error.error.message[0].message
                    ? error.error.message[0].message
                    : error.message;

                if (errorText === 'code must be unique') {
                    errorText =
                        'El codigo ya esta siendo usado por otra categoria';
                }

                toastr.error(errorText);
                return of(false);
            })
        );
    }

    getComboList(companyId?: any): Observable<Array<Category>> {
        return this.http
            .get(this.apiEndPoint.listAll.replace('{id}', companyId.toString()))
            .pipe(
                map((res) => {
                    return this.mapper.mapList(res, this.model);
                })
            );
    }

    getCategory(id: number): Observable<Category> {
        return this.http
            .get(this.apiEndPoint.get.replace('{id}', id.toString()))
            .pipe(
                map((res) => {
                    return BaseMapper.map(res, this.model);
                })
            );
    }

    uploadFile(file: any, path: string): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                apiKey: env.apiKey,
            }),
        };

        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);

        return this.fileClient
            .post(this.apiEndPoint.uploadFile, formData, headers)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
}
