import { GLinkConfig } from 'app/core/types';

/**
 * Default GLink Configuration
 *
 * You can edit these options to change the default options. All these options also can be changed per component
 * basis. See `core/pages/authentication/login/login.component.ts` constructor method to learn more
 * about changing these options per component basis.
 */

export const glinkConfig: GLinkConfig = {
    layout          : {
        style         : 'horizontal-layout-1',
        width         : 'fullwidth',
        navbar        : {
            hidden    : false,
            position  : 'top',
            folded    : false,
            background: 'mat-glink-dark-700-bg'
        },
        toolbar       : {
            hidden    : false,
            position  : 'above',
            background: 'mat-white-500-bg'
        },
        footer        : {
            hidden    : false,
            position  : 'above-static',
            background: 'mat-glink-dark-900-bg'
        }
    },
    customScrollbars: false
};
