import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule, MatFormFieldModule, MatInputModule, MatCheckboxModule } from '@angular/material';

 
import { GLinkCountdownModule } from 'app/core/components';

import { ComingSoonComponent } from 'app/core/pages/coming-soon/coming-soon.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes = [
    {
        path     : 'coming-soon',
        component: ComingSoonComponent
    }
];

@NgModule({
    declarations: [
        ComingSoonComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,

        FormsModule,
        ReactiveFormsModule, 
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
      
        GLinkCountdownModule
    ]
})
export class ComingSoonModule
{
}
