import { ModelBase } from '../../../../shared/models/model.base';
import { IAlbum } from 'ngx-lightbox';


export class ProductGallery  extends ModelBase implements IAlbum{
    src: string;
    caption?: string;
    thumb: string;
    cdnReference: string;

    constructor(){
        super();
        this.cdnReference = '' ;

    }
}
