export class ErrorType {
    code: number;
    title: string;
    errors: Array<string>;
    error:any;
    line: number;
    file: string;
    constructor() {
        this.code = 0;
        this.title = '';
        this.errors = [];
    }

    public errorsToString(): string {
        if (!this.errors) {
            return '';
        }

        return this.errors.join('\n');
    }
}
