import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule } from '@angular/material';

import { GLinkSearchBarModule, GLinkShortcutsModule } from 'app/core/components';
 

import { ToolbarComponent } from 'app/core/layout/components/toolbar/toolbar.component';
import { CoreSharedModule } from 'app/core/coreshared.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,

        CoreSharedModule,
      
        GLinkSearchBarModule,
        GLinkShortcutsModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
