import { ModelBase } from '../../../../shared/models/model.base';

export class Product extends ModelBase {
    companyId: string;
    name: string;
    description: string;
    image: string;
    status: boolean;
    category: string;
    price: string;
    size: string;

    constructor() {
        super();
    }
}
