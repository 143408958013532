import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material';

import { GLinkSidebarModule, GLinkThemeOptionsModule } from 'app/core/components';
 

import { ContentModule } from 'app/core/layout/components/content/content.module';
import { FooterModule } from 'app/core/layout/components/footer/footer.module';
import { NavbarModule } from 'app/core/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/core/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/core/layout/components/toolbar/toolbar.module';

import { HorizontalLayout1Component } from 'app/core/layout/horizontal/layout-1/layout-1.component';
import { CoreSharedModule } from 'app/core/coreshared.module';

@NgModule({
    declarations: [
        HorizontalLayout1Component
    ],
    imports     : [
        MatSidenavModule,

      
        GLinkSidebarModule,
        GLinkThemeOptionsModule,

        CoreSharedModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule
    ],
    exports     : [
        HorizontalLayout1Component
    ]
})
export class HorizontalLayout1Module
{
}
