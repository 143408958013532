import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatCheckboxModule } from '@angular/material';



import { ResetPasswordComponent } from 'app/core/pages/authentication/reset-password/reset-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

const routes = [
    {
        path     : 'auth/reset-password',
        component: ResetPasswordComponent
    }
];

@NgModule({
    declarations: [
        ResetPasswordComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        CommonModule,
        
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule, 
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule
    ]
})
export class ResetPasswordModule
{
}
