import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { CategoryMapper } from '../mappers/category.mapper';
import { Category } from '../models/category.type';
import { CATEGORIES_ENDPOINTS } from '../products.endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class CategoriesService extends BaseApiService<Category> {
    model = Category;
    mapper = new CategoryMapper();
    apiEndPoint: any = CATEGORIES_ENDPOINTS;

    getComboList(companyId?): Observable<Array<Category>> {
        let endpoint = this.apiEndPoint.list;
        if (companyId) {
            endpoint = endpoint + '?companyId=' + companyId;
        }

        return this.http.get(endpoint).pipe(
            map((res) => {
                return this.mapper.mapList(res, this.model);
            })
        );
    }
}
