import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import { ProductMapper } from '../mappers/product.mapper';
import { Product } from '../models/product.type';
import { ModifierMapper } from '../mappers/modifier.mapper';
import { Modifier } from '../models/modifier.type';
import { MODIFIER_ENDPOINT, PRODUCT_ENDPOINTS } from '../products.endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { SharedMapper } from 'app/shared/mappers/shared.mapper';
import { PagedApiResponse } from 'app/shared/models/paged-api-response.type';

@Injectable()
export class ProductService extends BaseApiService<Product> {
    model = Product;
    mapper = new ProductMapper();
    modifierMapper = new ModifierMapper();
    apiEndPoint: any = PRODUCT_ENDPOINTS;
    modifierEndPoint: any = MODIFIER_ENDPOINT;

    protected fileClient: HttpClient;
    constructor(
        http: HttpClient,
        public sharedMapper: SharedMapper,
        handler: HttpBackend
    ) {
        super(http, sharedMapper);
        this.fileClient = new HttpClient(handler);
    }
    updateStatus(id?: any, status?: any, companyId?: any): Observable<boolean> {
        return this.http.post<boolean>(this.apiEndPoint.status, {
            id,
            status,
            companyId,
        });
    }
    uploadExcel(url: any, categoryId: any): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                apiKey: env.apiKey,
                categoryId: categoryId,
            }),
        };

        const formData = new FormData();
        formData.append('path', url);

        var endpoint = this.apiEndPoint.uploadExcel.replace(
            '{id}',
            categoryId.toString()
        );

        return;
    }

    uploadFile(file: any, path: string): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                apiKey: env.apiKey,
            }),
        };

        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);

        return this.fileClient
            .post(this.apiEndPoint.uploadFile, formData, headers)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    getListFilter(filter: any): Observable<PagedApiResponse<Product>> {
        return this.http.post(this.apiEndPoint.filter, filter).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    Product
                );
            })
        );
    }
}
