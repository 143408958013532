import { ModelBase } from '../../../../shared/models/model.base';

export class Category extends ModelBase {
    id: number;
    companyId: string;
    code: string;
    name: string;
    lastName: string;
    description: string;
    image: string;
    icon: string;
    status: boolean;
    parentId: number;
    hierarchyLevel: number;
    priority: number;

    getTitle() {
        return `${this.name}`;
    }

    constructor() {
        super();
        this.id = 0;
        this.name = null;
        this.lastName = null;
        this.description = null;
        this.companyId = null;
        this.code = null;
        this.status = true;
        this.icon = null;
        this.image = null;
        this.parentId = null;
        this.priority = 1;
        this.hierarchyLevel = 1;
    }
}
