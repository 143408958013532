import * as _ from 'lodash';

export class BaseMapper<T> {

    public static map<T>(data: any, objectType: (new () => T)): T {
        return _.assign(new objectType(), data);
    }

    mapList(data: any, objectType: (new () => T)): Array<T> {
        return _.map(data, (item: any) => {
            return BaseMapper.map(item, objectType);
        });
    }
}
