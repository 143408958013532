export const GET_STATUS_INFO = (id: number) => {
  return SHIPPING_STATUSES_OPTIONS.filter(status => status.id === id)[0];
};

export const GET_STATUS_INFO_DB = (id: number) => {
  return SHIPPING_STATUSES_OPTIONS.filter(status => status.id === id)[0];
};

export const SHIPPING_STATUSES = {
  INITIAL: 1,
  PREPARING: 2,
  SHIPPED: 3,
  DELIVERED: 4,
  CANCELLED: 5,
  REFUNDED: 6,
  RETURNED: 7
};

export const SHIPPING_STATUSES_OPTIONS = [
  {
    'id': 1,
    'url': 'news',
    'name': 'Initial',
    'color': 'mat-red-bg',
    'fgcolor': 'red-fg',
  },
  {
    'id': 2,
    'url': 'preparing',
    'name': 'Preparing',
    'color': 'mat-orange-800-bg',
    'fgcolor': 'orange-800-fg',
  },
  {
    'id': 3,
    'url': 'shipped',
    'name': 'Shipped',
    'color': 'mat-blue-500-bg',
    'fgcolor': 'blue-500-fg',
  },
  {
    'id': 4,
    'url': 'delivered',
    'name': 'Delivered',
    'color': 'mat-green-600-bg',
    'fgcolor': 'green-600-fg',
  },
  {
    'id': 5,
    'url': 'cancelled',
    'name': 'Cancelled',
    'color': 'mat-grey-600-bg',
    'fgcolor': 'grey-600-fg',
  },
  {
    'id': 6,
    'url': '',
    'name': 'Refunded',
    'color': 'mat-grey-600-bg',
    'fgcolor': 'grey-600-fg',
  },
  {
    'id': 7,
    'url': '',
    'name': 'Returned',
    'color': 'mat-blue-grey-600-bg',
    'fgcolor': 'blue-grey-600-fg',
  }
];
