import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SUBSCRIPTIONS_ANALYTICS_ENDPOINTS } from '../subscriptions.endpoints';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

@Injectable()
export class SubscriptionsAnalyticsService {
  
  apiEndPoint: any = SUBSCRIPTIONS_ANALYTICS_ENDPOINTS;

  // Constructor.
  constructor ( private http: HttpClient ) {}

  /**
   * Obtener la cantidad de subscripciones en periodo de los últimos dos días
   * de los documentos indexados en ElasticSearch.
   * @param companyId ID de la compañia que se está consultando.
   */
  getSubscriptionsCountDaily(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.daily, { "companyId": companyId })
    .pipe( map( (res: Response) => res), 
      catchError ( (res: Response) =>  this.onError(res) ));
  }
 
  /**
   * Obtener la cantidad de subscripciones de las últimas 2 semanas
   * de los documentos indexados en ElasticSearch.
   * @param companyId ID de la compañia que se está consultando.
   */
  getSubscriptionsCountWeekly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.weekly, { "companyId": companyId })
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Obtener la cantidad de subscripciones en el período de los últimos dos meses
   * de los documentos indexados en ElasticSearch.
   * @param companyId ID de la compañia que se está consultando.
   */
  getSubscriptionsCountMonthly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.monthly, {"companyId": companyId})
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Obtener la cantidad de subscripciones de los dos últimos trimestres 
   * de los documentos indexados en ElasticSearch.
   * @param companyId ID de la compañia que se está consultando.
   */
  getSubscriptionsCountQuarterly(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.quarterly, {"companyId": companyId})
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Obtener la cantidad de subscripciones totales hasta el momento actual 
   * de los documentos indexados en ElasticSearch.
   * @param companyId ID de la compañia que se está consultando.
   */
  getTotalSubscriptionsCount(companyId: number): Observable<any> {
    return this.http.post(this.apiEndPoint.quarterly, {"companyId": companyId})
    .pipe( map( (res: Response) => res), 
    catchError ( (res: Response) =>  this.onError(res) ));
  }

  /**
   * Crear un objeto que informará el error al consultar el EndPoint.
   * @param res 
   */
  onError(res: Response) {
    const error = {
      statusCode: res.status,
      statusMessage: 'Verificar el servicio ES esté activo y verificar Analytics EndPoints',
      error: res['message']

    };
    return throwError(error);
  }
}
