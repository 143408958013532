import { ModelBase } from '../../../shared/models/model.base';

export class Contact extends ModelBase {
  name: '';
  role: '';
  email: '';
  phoneNumber: '';

  constructor() {
    super();
  }
}
