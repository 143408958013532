import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { GLinkNavigationService } from 'app/core/components/navigation/navigation.service';
import { GLinkPerfectScrollbarDirective } from 'app/core/directives/glink-perfect-scrollbar/glink-perfect-scrollbar.directive';
import { GLinkSidebarService } from 'app/core/components/sidebar/sidebar.service';

@Component({
    selector     : 'navbar',
    templateUrl  : './navbar.component.html',
    styleUrls    : ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy
{
    // Layout
    @Input()
    layout;

    glinkPerfectScrollbarUpdateTimeout: any;
    navigation: any;

    // Private
    private _glinkPerfectScrollbar: GLinkPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {GLinkNavigationService} _glinkNavigationService
     * @param {GLinkSidebarService} _glinkSidebarService
     * @param {Router} _router
     */
    constructor(
        private _glinkNavigationService: GLinkNavigationService,
        private _glinkSidebarService: GLinkSidebarService,
        private _router: Router
    )
    {
        // Set the defaults
        this.layout = 'vertical';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(GLinkPerfectScrollbarDirective)
    set directive(theDirective: GLinkPerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._glinkPerfectScrollbar = theDirective;

        this._glinkNavigationService.onItemCollapseToggled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.glinkPerfectScrollbarUpdateTimeout = setTimeout(() => {
                    this._glinkPerfectScrollbar.update();
                }, 310);
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._glinkSidebarService.getSidebar('navbar') )
                    {
                        this._glinkSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Get current navigation
        this._glinkNavigationService.onNavigationChanged
            .pipe(filter(value => value !== null))
            .subscribe(() => {
                this.navigation = this._glinkNavigationService.getCurrentNavigation();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        if ( this.glinkPerfectScrollbarUpdateTimeout )
        {
            clearTimeout(this.glinkPerfectScrollbarUpdateTimeout);
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._glinkSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._glinkSidebarService.getSidebar('navbar').toggleFold();
    }
}
