import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadCrumb } from './breadcrumb';
import { Location } from '@angular/common';

import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit {
    breadcrumbs: Array<BreadCrumb>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private location: Location
    ) {}

    ngOnInit() {
        const self = this;
        setInterval(function () {
            self.breadcrumbs = self.buildBreadCrumb(self.activatedRoute.root);
        }, 500);
    }

    buildBreadCrumb(
        route: ActivatedRoute,
        url: string = '',
        breadcrumbs: Array<BreadCrumb> = []
    ): Array<BreadCrumb> {
        // If no routeConfig is avalailable we are on the root path
        const label = route.routeConfig
            ? 'data' in route.routeConfig
                ? route.routeConfig.data['breadcrumb']
                : ''
            : 'home';
        const path = route.routeConfig ? route.routeConfig.path : '';

        // In the routeConfig the complete path is not available,
        // so we rebuild it each time
        const nextUrl = `${url}${path}/`;
        const breadcrumb = {
            label: label,
            url: nextUrl,
        };

        if (typeof breadcrumb.label === 'object') {
            breadcrumb.label = '';
        }
        const newBreadcrumbs = [...breadcrumbs, breadcrumb];

        if (route.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this.buildBreadCrumb(
                route.firstChild,
                nextUrl,
                newBreadcrumbs
            );
        }

        return newBreadcrumbs.filter((bc) => {
            if (bc.label) {
                return bc;
            }
        });
    }

    back() {
        this.location.back();
    }
}
