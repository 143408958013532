import { Routes } from '@angular/router';
import { LoggedUserGuard } from './shared/guards/logged-user.guard';
import { UnLoggedUserGuard } from './shared/guards/unlogged-user.guard';
import { Error404Component } from './core/pages/errors/404/error-404.component';

export const ROUTES: Routes = [
    {
        path: '',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/webhome/webhome.module#WebHomeModule',
    },
    {
        path: 'ads',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/ads/ads.module#AdsModule',
    },
    {
        path: 'promotions',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/promotions/promotions.module#PromotionsModule',
    },
    {
        path: 'webhome',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/webhome/webhome.module#WebHomeModule',
    },
    {
        path: 'reports',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/reporting/reporting.module#ReportingModule',
    },
    {
        path: 'donations',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/donations/donations.module#DonationsModule',
        data: {
            breadcrumb: 'Donaciones',
        },
    },
    {
        path: 'customers',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/customers/customers.module#CustomersModule',
        data: {
            breadcrumb: 'Clientes',
        },
    },
    {
        path: 'shippings',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/shippings/shippings.module#ShippingsModule',
        data: {
            breadcrumb: 'Órdenes',
        },
    },
    {
        path: 'billings',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/billing/billing.module#BillingModule',
        data: {
            breadcrumb: 'Ventas',
        },
    },
    {
        path: 'mycompany',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/mycompany/mycompany.module#MyCompanyModule',
    },
    {
        path: 'products',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/products/products.module#ProductsModule',
        data: {
            breadcrumb: 'Productos',
        },
    },
    {
        path: 'marketplace',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/marketplace/marketplace.module#MarketPlaceModule',
    },
    {
        path: 'subscriptions',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/subscriptions/subscriptions.module#SubscriptionsModule',
    },
    {
        path: 'login',
        canActivate: [UnLoggedUserGuard],
        loadChildren:
            './core/pages/authentication/login/login.module#LoginModule',
    },
    {
        path: 'forgot-password',
        canActivate: [UnLoggedUserGuard],
        loadChildren:
            './core/pages/authentication/forgot-password/forgot-password.module#ForgotPasswordModule',
    },
    {
        path: 'reset-password',
        canActivate: [UnLoggedUserGuard],
        loadChildren:
            './core/pages/authentication/reset-password/reset-password.module#ResetPasswordModule',
    },
    {
        path: 'customersSaleOrder',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/customersSaleOrder/customers-sale-order.module#CustomersSaleOrderModule',
        data: {
            breadcrumb: 'Clientes',
        },
    },
    {
        path: 'appnotifications',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/appnotifications/appnotifications.module#AppNotificationsModule',
        data: {
            breadcrumb: 'Notificaciones',
        },
    },
    {
        path: 'paymentGateway',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/paymentGateway/paymentGateway.module#PaymentGatewayModule',
    },
    {
        path: 'roles',
        canActivate: [LoggedUserGuard],
        loadChildren: './admin/roles/role.module#RoleModule',
    },
    {
        path: 'companies',
        canActivate: [LoggedUserGuard],
        loadChildren: './admin/companies/company.module#CompanyModule',
    },
    {
        path: 'users',
        canActivate: [LoggedUserGuard],
        loadChildren: './admin/users/user.module#UserModule',
    },
    {
        path: 'emailing',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/emailing/emailing.module#EmailingModule',
    },
    {
        path: 'reminder',
        canActivate: [LoggedUserGuard],
        loadChildren: './features/reminder/reminder.module#ReminderModule',
    },
    {
        path: 'subscriptionsSms',
        canActivate: [LoggedUserGuard],
        loadChildren:
            './features/subscriptionsSms/subscriptionsSms.module#SubscriptionsSmsModule',
    },
    {
        path: '**',
        component: Error404Component,
    },
];
