import { Component, Inject, OnDestroy } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatSlideToggleModule,
} from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {
    OrderFiltersExportExcel,
    OrderResultsExport,
} from '../../../models/order-filters-export-excel.type';
import { ShippingOrderService } from '../../../services/shipping-order.service';
import { FilterType } from '../../../../../../shared/models/filter.type';
import { GLinkUtils } from 'app/core/utils';
import { MessageType } from '../../../../../../shared/enums';

const EXCEL_EXTENSION = '.xlsx';
const ALPHA = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

/**
 * Este es el formulario que contiene 3 campos que sirven para busqueda personalizada para exportar
 * la lista de órdenes a una hoja de excel con el detalle de las mismas.
 * Filtros:
 * filterbyStatusCtrl => Estado de la orden, si no se elije se incluyen todos los estados en el reporte.
 * filterbyDateIniCtrl => Fecha desde donde se consulta la información, es requerido.
 * filterbyDateFinCtrl => Fecha hasta la cual se consulta la info de las órdenes, no es requerido. En caso
 *                         que no se especifique, se incluyen las órdenes elaboradas en >= fecha actual.
 */
@Component({
    selector: 'app-export-order-list-excel',
    templateUrl: './exportOrderListExcel.component.html',
    providers: [ShippingOrderService],
})
export class ExportOrderListExcelComponent implements OnDestroy {
    filter: FilterType = new FilterType();
    filterItemsExport: OrderFiltersExportExcel = new OrderFiltersExportExcel();
    FilterByStatusForm: FormGroup;

    dialogTitle: string;
    loading: boolean = false;
    fileName: string;
    ordersArray: any[];
    selectedFieldsMaster = [
        {
            displayName: 'Referencia orden',
            fieldName: 'orderReference',
            type: 's',
            format: '',
        },
        {
            displayName: 'Fecha estimada envio',
            fieldName: 'estimatedShippingDate',
            type: 's',
            format: ' d mmmm yy',
        },
        {
            displayName: 'Nombre cliente',
            fieldName: 'customerName',
            type: 's',
            format: '',
        },
        {
            displayName: 'Identificación',
            fieldName: 'identificationNumber',
            type: 's',
            format: '',
        },
        { displayName: 'Email', fieldName: 'email', type: 's', format: '' },
        { displayName: 'Ciudad', fieldName: 'city', type: 's', format: '' },
        {
            displayName: 'Dirección de entrega 1',
            fieldName: 'shippingAddress1',
            type: 's',
            format: '',
        },
        {
            displayName: 'Dirección de entrega 2',
            fieldName: 'shippingAddress2',
            type: 's',
            format: '',
        },
    ];

    selectedFieldsDetails = [
        {
            displayName: 'Descripción del Producto',
            fieldName: 'productDescription',
            type: 's',
            format: '',
        },
        {
            displayName: 'Cantidad',
            fieldName: 'quantity',
            type: 'n',
            format: '0',
        },
        {
            displayName: 'Precio',
            fieldName: 'price',
            type: 'n',
            format: '$ #,##0.00',
        },
    ];

    constructor(
        public dialogRef: MatDialogRef<ExportOrderListExcelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _service: ShippingOrderService,
        private _formBuilder: FormBuilder
    ) {
        this.dialogTitle = 'Exportar Excel';

        // Inicialización y validaciones de los campos filtro del formulario.
        this.FilterByStatusForm = this._formBuilder.group({
            filterbyStatusCtrl: [''],
            filterbyDateFinCtrl: [''],
            filterbyDateIniCtrl: ['', Validators.required],
        });

        this.FilterByStatusForm.controls[
            'filterbyStatusCtrl'
        ].valueChanges.subscribe((value) => {
            this.filterItemsExport.status = value;
        });
        this.FilterByStatusForm.controls[
            'filterbyDateIniCtrl'
        ].valueChanges.subscribe((value) => {
            this.filterItemsExport.dateIni = moment(value).format('YYYY-MM-DD');
        });
        this.FilterByStatusForm.controls[
            'filterbyDateFinCtrl'
        ].valueChanges.subscribe((value) => {
            this.filterItemsExport.dateFin = moment(value).format('YYYY-MM-DD');
        });
    }

    /**
     * - Valida que los datos recibidos de los filtros sean en el formato esperado.
     * - Prepara la data a exportar, sino existe data se advierte al usuario.
     */
    filterByDatesAndStatus(): void {
        // Confirmar si se ha recibido una fecha de inicio válida para generar el archivo.
        let tmpDate: string = String(this.filterItemsExport.dateIni);
        const assertDate = moment(tmpDate, 'YYYY-MM-DD', true);

        if (assertDate.isValid()) {
            try {
                this.loading = true;
                this._service
                    .getListOrdersExportExcel(
                        this.filter,
                        this.filterItemsExport
                    )
                    .subscribe((res) => {
                        this.ordersArray = res.rows;
                        this.loading = false;

                        if (res.count > 0) {
                            let dateFinFile =
                                this.filterItemsExport.dateFin != null
                                    ? this.filterItemsExport.dateFin
                                    : moment(new Date()).format('YYYY-MM-DD');
                            this.fileName = `Ordenes_from_${this.filterItemsExport.dateIni}_to_${dateFinFile}${EXCEL_EXTENSION}`;
                            this.exportAsExcelFile();
                        } else {
                            const MSG =
                                'No existen resultados, intente otro filtro de búsqueda.';
                            GLinkUtils.showMessage(
                                'Exportando resultados...',
                                MSG,
                                MessageType.info,
                                false,
                                'Ok',
                                '',
                                undefined,
                                undefined
                            );
                        }
                    });
            } catch (err) {}
        }
    }

    /**
     * Asignar a cada celda de la hoja de cálculo su valor, basado en el arreglo de objetos que
     * se obtiene del servicio shipping-orders.
     */
    exportAsExcelFile(): void {
        let self = this;

        const workbook: XLSX.WorkBook = {
            SheetNames: ['Ordenes'],
            Sheets: {
                Ordenes: {
                    '!ref': 'A1:',
                    '!cols': [{ wch: 26 }],
                },
            },
        };

        let lineNum = 1;
        self.ordersArray.forEach((offer) => {
            // Imrpimir el encabezado de la info del cliente.
            for (let h = 0; h < this.selectedFieldsMaster.length; h++) {
                let currentCell = this.calculateCurrentCellReference(
                    h,
                    lineNum
                );
                workbook.Sheets['Ordenes'][currentCell] = {
                    t: 's',
                    v: this.selectedFieldsMaster[h].displayName,
                    /* La propiedad que da estilo a las celdas es pagado 
              https://gist.github.com/SheetJSDev/24b8acd317d01999d721b38de7c53021

          s: {
            //font: { sz: "16", bold: true},
            fill: { bgColor: { argb:"FF1c4587" } }  
          }*/
                };
            }
            lineNum++;
            // Cada fila de información sobre la Orden-Cliente
            let fieldMap = offer;
            for (let i = 0; i < this.selectedFieldsMaster.length; i++) {
                let displayValue =
                    fieldMap[this.selectedFieldsMaster[i].fieldName];
                let formatCell = this.selectedFieldsMaster[i].format;
                let currentCell = self.calculateCurrentCellReference(
                    i,
                    lineNum
                );
                workbook.Sheets['Ordenes'][currentCell] = {
                    t: this.selectedFieldsMaster[i].type,
                    v: displayValue != null ? displayValue : '',
                };
            }
            lineNum++;
            // Imprimir el encabezado de la info del detalle de productos por cliente
            for (let j = 0; j < this.selectedFieldsDetails.length; j++) {
                let currentCell = self.calculateCurrentCellReference(
                    j,
                    lineNum
                );
                workbook.Sheets['Ordenes'][currentCell] = {
                    t: 's',
                    v: this.selectedFieldsDetails[j].displayName,
                    s: { font: { bold: true } },
                };
            }
            lineNum++;
            offer.products.forEach((itemProduct) => {
                let fieldProductMap = itemProduct;
                for (let k = 0; k < this.selectedFieldsDetails.length; k++) {
                    let displayValueProduct =
                        fieldProductMap[
                            this.selectedFieldsDetails[k].fieldName
                        ];
                    let formatCell = this.selectedFieldsDetails[k].format;
                    let currentCell = self.calculateCurrentCellReference(
                        k,
                        lineNum
                    );
                    workbook.Sheets['Ordenes'][currentCell] = {
                        t: this.selectedFieldsDetails[k].type,
                        v:
                            displayValueProduct != null
                                ? displayValueProduct
                                : '',
                        z: formatCell != null ? formatCell : '',
                    };
                }
                lineNum++;
            });
            lineNum++;
        });

        let lastColumnInSheet = this.selectedFieldsMaster.length - 1;
        let endOfRange = self.calculateCurrentCellReference(
            lastColumnInSheet,
            lineNum
        );
        workbook.Sheets['Ordenes']['!ref'] += endOfRange;
        XLSX.writeFile(workbook, this.fileName);
    }

    /**
     * Calcular la celda donde se escribirá cada valor en la hoja de cálculo.
     * @param index Posición del cursor en las primeras 26 columnas de la hoja de cálculo.
     * @param lineNumber Número de la línea en la que está el cursor
     */
    calculateCurrentCellReference(index: number, lineNumber: number) {
        return index > 25
            ? ALPHA[Math.floor(index / 26 - 1)] + ALPHA[index % 26] + lineNumber
            : ALPHA[index] + lineNumber;
    }
    /**
     * Accion de cerrar el formulario de filtros.
     */
    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
