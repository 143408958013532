export class OrderFiltersExportExcel {
    status: Number;
    dateIni: String;
    dateFin: String;
}

export class OrderResultsExport {
    orderReference: string;
    estimatedShippingDate: string;
    customerName: string;
    identificationNumber: string;
    phoneNumber: string;
    email: string;
    city: string;
    shippingAddress1: string;
    shippingAddress2: string;
    products: Array<OrderProductsDetails>;
    
  }
  
  class OrderProductsDetails {
      productDescription: string;
      quantity: string;
      price: string;
  }
  
  