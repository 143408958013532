import * as _ from 'lodash';

export class ModelBase {
    id: number;

    clone() {
        if (this.id !== undefined) {
            this.id = undefined;
        }

        return _.clone(this);
    }

    copy() {
        return _.clone(this);
    }
}
