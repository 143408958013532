import { Component, Inject, OnInit, Input, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validator,
    Validators,
} from '@angular/forms';
import { ProductService } from '../../../services/products.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '../../../services/categories.service';
import { Category } from '../../../models/category.type';
import * as toastr from 'toastr';

@Component({
    selector: 'import-product-excel',
    templateUrl: './importProductExcel.component.html',
    styleUrls: ['./importProductExcel.component.scss'],
    providers: [ProductService, CategoriesService],
})
export class ImportProductExcelComponent implements OnDestroy {
    service: ProductService;
    @Input() id_pro: number;

    action: string;
    dialogTitle: string;
    id: number;
    callback: (boolean) => void;
    isWaiting = false;
    categoryId: string;

    filteredCategories: Observable<Array<Category>>;

    allCategories: Array<Category>;

    constructor(
        public dialogRef: MatDialogRef<ImportProductExcelComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _service: ProductService,
        private categoryService: CategoriesService,
        private route: ActivatedRoute
    ) {
        this.dialogTitle = 'Importar Excel';
        this.callback = data.callback;
        this.service = _service;

        this.route.queryParams.subscribe((params) => {
            this.categoryService
                .getComboList(params[0])
                .subscribe((categories) => {
                    this.allCategories = categories;
                });
        });
    }

    saveExcel(url) {
        var response = JSON.parse(url);

        switch (response.status) {
            case 'SUCCESS':
                toastr.success('Registros creados exitosamente.', 'Producto');
                break;

            case 'WARNING':
                toastr.warning(response.message);
                break;

            case 'ERROR':
                toastr.error(response.message);

                break;
            default:
                break;
        }

        this.isWaiting = false;
    }

    close() {
        this.dialogRef.close();
    }

    select(value) {
        this.categoryId = value;
    }

    getCategoryId() {
        return this.categoryId;
    }
    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
