import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatCheckboxModule } from '@angular/material';

 

import { LockComponent } from 'app/core/pages/authentication/lock/lock.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes = [
    {
        path     : 'auth/lock',
        component: LockComponent
    }
];

@NgModule({
    declarations: [
        LockComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        CommonModule,
        
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule, 
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule

         
    ]
})
export class LockModule
{
}
