import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(this.formatDate(value), 'dd-MM-yyyy');
  }

  formatDate(obj: any){
    if (obj){
      return obj.toString().replace(/\s/g, 'T');
    }
    return obj;
    
}
}
