import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';

import { GLinkDirectivesModule } from 'app/core/directives/directives';
import { GLinkSidebarModule } from 'app/core/components/sidebar/sidebar.module';
import { GLinkMaterialColorPickerModule } from 'app/core/components/material-color-picker/material-color-picker.module';

import { GLinkThemeOptionsComponent } from 'app/core/components/theme-options/theme-options.component';

@NgModule({
    declarations: [
        GLinkThemeOptionsComponent
    ],
    imports     : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,

        GLinkDirectivesModule,
        GLinkMaterialColorPickerModule,
        GLinkSidebarModule
    ],
    exports     : [
        GLinkThemeOptionsComponent
    ]
})
export class GLinkThemeOptionsModule
{
}
