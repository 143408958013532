import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecordAction } from '../../enums';

export interface AutoCompleteModel {
    value: any;
    display: string;
}

@Component({
    selector: 'app-tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit {
    // tslint:disable-next-line: no-input-rename
    @Input('options') options: any;
    // tslint:disable-next-line: no-input-rename
    @Input('selectedOptions') selectedOptions: any;

    @Input() actionInput: RecordAction;
    items: any = [];
    roleChip: any;

    @Output() tagAddItem = new EventEmitter();
    @Output() tagDeleteItem = new EventEmitter();

    constructor() {}

    ngOnInit() {
        if (this.actionInput === RecordAction.Update) {
            for (const element of this.selectedOptions) {
                this.items.push({ display: element.name, value: element.id });
            }
        }
    }

    onAdd(item: any) {
        this.tagAddItem.emit(item);
    }

    onRemove(item: any) {
        this.tagDeleteItem.emit(item);
    }

    onClean() {
        this.items = [];
    }
}
