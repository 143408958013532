import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';

@Directive({
  selector: '[spinnerButton]'
})
export class SpinnerButtonDirective implements OnInit, OnChanges {


@Input('spinnerButton') isWaiting: boolean;
  originalInnerText: string;
  constructor( private el: ElementRef ) { 
  }

  ngOnInit(){
    this.originalInnerText = this.el.nativeElement.innerText;
  }

  ngOnChanges() {
      
  

    if (this.isWaiting) {
      this.el.nativeElement.innerHTML = '<i style="font-size:21px" class="fa fa-refresh fa-spin"></i>';
    } else {
      if (this.el.nativeElement.innerHTML === '<i style="font-size:21px" class="fa fa-refresh fa-spin"></i>') {
        this.el.nativeElement.innerHTML =  '<span>' + this.originalInnerText + '</span>';
      }
    }
    this.el.nativeElement.disabled = this.isWaiting;
  }

}
