import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatToolbarModule, MatInputModule, 
  MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatSelectModule, MatCheckboxModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { environment as env } from '../environments/environment';


// Componentes
import { GLinkSidebarModule, GLinkThemeOptionsModule } from 'app/core/components';
// import { FormCustomerAccountsComponent } from './features/sample/sample-submodule/components/form-customer-accounts/form-customer-accounts.component';

import { glinkConfig } from 'app/app.config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/core/layout/layout.module';
import { CoreModule } from 'app/core/core.module';
import { ROUTES } from './app.routes';
import { GLinkSplashScreenService } from './core/services/splash-screen.service';
import { GLinkConfigService } from './core/services/config.service';
import { GLinkNavigationService } from './core/components/navigation/navigation.service';
import { ObservableDataService } from './core/services/observable-data.service';
import { LoggedUserGuard } from './shared/guards/logged-user.guard';
import { DefaultReportGuard } from './shared/guards/default-report.guard';
import { UnLoggedUserGuard } from './shared/guards/unlogged-user.guard';
import { CustomErrorHandler } from './core/services/custom-error-handler.service';
import { JwtModule } from '@auth0/angular-jwt';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ContactFormComponent } from './features/mycompany/components/contact-form/contact-form.component';
import { DialogContentComponent } from './shared/components/dialog-content/dialog-content.component';
import { SharedModule} from './shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShippingOrderFormComponent } from './features/shippings/shipping-orders/components/order/shipping-order-form/shipping-form.component';
import { AddProductImagesComponent } from './features/products/products/components/product/tabs/gallery/addProductImages/addProductImages.component';
import { ImportProductExcelComponent } from './features/products/products/components/products-list/importProductExcel/importProductExcel.component';
import { ExportOrderListExcelComponent } from './features/shippings/shipping-orders/components/orders-list/exportOrderListExcel/exportOrderListExcel.component';
import { ShippingOrderService } from './features/shippings/shipping-orders/services/shipping-order.service';
import { SalesAnalyticsService } from './features/reporting/sales/services/sales-analytics.service';
import { SubscriptionsAnalyticsService } from './features/reporting/sales/services/subscriptions-analytics.service';
import { RoleActionFormComponent } from './admin/roles/components/role-item/components/role-action-form/role-action-form.component';
import { ModulePermissionGuard } from './shared/guards/module-permission.guard';


@NgModule({
  declarations: [
    AppComponent,
    ContactFormComponent,
    ShippingOrderFormComponent,
    AddProductImagesComponent,
    ImportProductExcelComponent, 
    ExportOrderListExcelComponent,
    RoleActionFormComponent,
  ],
  imports: [
  BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,

    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatSelectModule,
    SharedModule,
    MatCheckboxModule,
    // GLink modules
    CoreModule.forRoot(glinkConfig),

    GLinkSidebarModule,
    GLinkThemeOptionsModule,

    // App modules
    LayoutModule, 
    FlexLayoutModule,

    RouterModule.forRoot(ROUTES),


    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: [env.UpLink_ApiUrl, env.Fake_ApiUrl, 'http://api.uplink.com/services/']
      }
    })
  ],
  providers: [
    GLinkSplashScreenService,
    GLinkConfigService,
    GLinkNavigationService,
    ObservableDataService,
    LoggedUserGuard,
    UnLoggedUserGuard,
    DefaultReportGuard,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ShippingOrderService,
    SalesAnalyticsService,
    SubscriptionsAnalyticsService,
    ModulePermissionGuard,
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ContactFormComponent,
    ShippingOrderFormComponent,
    DialogContentComponent,
    AddProductImagesComponent,
    ImportProductExcelComponent,
    ExportOrderListExcelComponent,
    RoleActionFormComponent,
  ]
})
export class AppModule {
}


export function getToken() {
  const info = localStorage.getItem(env.accessTokenLocalStorageKey);

  if (info) {
    return JSON.parse(info).token;
  }
}
