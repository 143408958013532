import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { GLinkConfigService } from 'app/core/services/config.service';
import { GLinkSidebarService } from 'app/core/components/sidebar/sidebar.service';
import { navigation } from 'app/app.navigation';
import { ObservableDataService } from 'app/core/services/observable-data.service';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    userStatusOptions: any[];
    avatarUrl: string;
    fullName: string;
    isOwner: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {GLinkConfigService} _glinkConfigService
     * @param {GLinkSidebarService} _glinkSidebarService
     * @param {Router} _router
     * @param {TranslateService} _translateService
     */
    constructor(
        private _glinkConfigService: GLinkConfigService,
        private _glinkSidebarService: GLinkSidebarService,
        private _router: Router,
        private _translateService: TranslateService,
        private ods: ObservableDataService,
        private router: Router,
        private authService: AuthenticationService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr',
            },
        ];

        this.navigation = navigation;

        this.ods.loggedUser.subscribe((res) => {
            if (!res.avatarUrl) {
                return;
            }

            this.avatarUrl = res.avatarUrl;
            this.fullName = res.fullName;
            this.isOwner = res.isOwner;
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the router events to show/hide the loading bar
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((event) => {
                this.showLoadingBar = true;
            });

        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.showLoadingBar = false;
            });

        // Subscribe to the config changes
        this._glinkConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._glinkSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param langId
     */
    setLanguage(langId): void {
        // Set the selected language for toolbar
        this.selectedLanguage = _.find(this.languages, { id: langId });

        // Use the selected language for translations
        this._translateService.use(langId);
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
}
