import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProductGalleryService } from './../../../../../services/productGallery.service';

@Component({
    selector: 'wtf-tab-productgallery-add',
    templateUrl: './addProductImages.component.html',
    providers: [ProductGalleryService],
})
export class AddProductImagesComponent implements OnDestroy {
    action: string;
    dialogTitle: string;
    productId: number;
    companyId: number;
    productSKU: string;
    imagePath: string;
    callback: (boolean, string) => void;

    constructor(
        public dialogRef: MatDialogRef<AddProductImagesComponent>,
        private service: ProductGalleryService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.dialogTitle = 'Agregar imágenes';
        this.productId = data.idProduct;
        this.productSKU = data.skuProduct;
        this.companyId = data.companyId;
        this.callback = data.callback;
        //this.imagePath = '/sweetCoffee/productos/' + this.productSKU;
        this.imagePath = '/sweetCoffee/productos';
    }

    imageUploaded(url) {
        this.service
            .create({ cdnReference: url, productId: this.productId })
            .subscribe((result) => {
                this.close(url);
            });
    }

    close(url: any = '') {
        this.callback(true, url);
        this.dialogRef.close();
    }

    uploadCompleteAll(status) {}

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
