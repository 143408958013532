import { environment as env } from '../../../../environments/environment';

const BASE_URL = env.UpLink_ApiUrl; 
// const BASE_URL = env.Analytics_ApiUrl; 

export const SALES_ANALYTICS_ENDPOINTS = {
    daily: `${BASE_URL}api/analytics/billing_transactions/daily`,
    weekly: `${BASE_URL}api/analytics/billing_transactions/weekly`,
    monthly: `${BASE_URL}api/analytics/billing_transactions/monthly`,
    quarterly: `${BASE_URL}api/analytics/billing_transactions/quarterly`
};
