import { GLinkNavigation } from 'app/core/types';

export const navigation: GLinkNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        type     : 'group',
        icon     : 'apps',
        children : [
             {
                 id       : 'sample',
                 title    : 'Sample',
                 type     : 'item',
                 icon     : 'email',
                 url      : '/',
                 badge    : {
                     title    : '25',
                     bg       : '#F44336',
                     fg       : '#FFFFFF'
                 }
             },
                 {
                     id       : 'datatable',
                     title    : 'DataTable',
                     type     : 'item',
                     icon     : 'table',
                     url      : '/datatable'
             }
           
        ]
    }
];

export const shortcutItems = [
    {
        'title': 'Sample',
        'type' : 'item',
        'icon' : 'email',
        'url'  : '/sample'
    }
];
