import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../../shared/services/base.api.service';
import {
    SHIPPING_ORDER_ENDPOINTS,
    CUSTOMER_ENDPOINTS,
    SHIPPING_ITEMS_ENDPOINTS,
    SALES_ORDER_ENDPOINTS,
} from '../shipping-orders.endpoints';
import { ShippingOrder } from '../models/shipping-order.type';
import { ShippingOrderMapper } from '../mappers/shipping-order.mapper';
import { map, catchError } from 'rxjs/operators';
import { FilterType } from 'app/shared/models/filter.type';
import { Observable } from 'rxjs';
import { PagedApiResponse } from 'app/shared/models/paged-api-response.type';
import { OrderResultsExport } from '../models/order-filters-export-excel.type';
import * as toastr from 'toastr';
import { of } from 'rxjs';

@Injectable()
export class ShippingOrderService extends BaseApiService<ShippingOrder> {
    model = ShippingOrder;
    modelOrderExport = OrderResultsExport;
    mapper = new ShippingOrderMapper();
    apiEndPoint: any = SHIPPING_ORDER_ENDPOINTS;
    salesOrderEndPoint: any = SALES_ORDER_ENDPOINTS;
    customerApiEndPoint: any = CUSTOMER_ENDPOINTS;
    itemsApiEndPoint: any = SHIPPING_ITEMS_ENDPOINTS;

    generatePDF(reference: string): Observable<any> {
        return this.http.get(this.apiEndPoint.pdf.replace('{id}', reference), {
            responseType: 'blob',
        });
    }

    getList(
        filter?: FilterType,
        conditionals?: any
    ): Observable<PagedApiResponse<ShippingOrder>> {
        return this.http
            .post(this.apiEndPoint.list, { ...filter, ...conditionals })
            .pipe(
                map((res) => {
                    return this.sharedMapper.mapPagedApiResponse(
                        res,
                        this.mapper.mapList,
                        this.model
                    );
                })
            );
    }

    getListCustom(
        filter?: FilterType,
        conditionals?: any
    ): Observable<PagedApiResponse<ShippingOrder>> {
        return this.http
            .post(this.apiEndPoint.listCustom, { ...filter, ...conditionals })
            .pipe(
                map((res) => {
                    return this.sharedMapper.mapPagedApiResponse(
                        res,
                        this.mapper.mapList,
                        this.model
                    );
                })
            );
    }

    /**
     * Listar las ordenes en todos sus estados para exportar el reporte a un documento Excel.
     * @param filter El filtro de busqueda incluye: estadso y rango de fecha.
     * @param conditionals
     */
    getListOrdersExportExcel(
        filter?: FilterType,
        conditionals?: any
    ): Observable<PagedApiResponse<any>> {
        return this.http
            .post(this.apiEndPoint.listOrdersExportExcel, {
                ...filter,
                ...conditionals,
            })
            .pipe(
                map((res) => {
                    return this.sharedMapper.mapPagedApiResponse(
                        res,
                        this.mapper.mapList,
                        this.modelOrderExport
                    );
                })
            );
    }

    getCustomer(id: number) {
        return this.http
            .get(this.customerApiEndPoint.item.replace('{id}', id.toString()))
            .pipe(
                map((customer) => {
                    return customer;
                })
            );
    }
    getSaleOrder(id: number) {
        return this.http
            .get(this.apiEndPoint.saleOrder.replace('{id}', id.toString()))
            .pipe(
                map((items) => {
                    return items;
                })
            );
    }

    getStatusPayment(id: number) {
        return this.http
            .get(
                this.salesOrderEndPoint.saleOrderPayment.replace(
                    '{id}',
                    id.toString()
                )
            )
            .pipe(
                map((item) => {
                    return item;
                })
            );
    }

    getPaymentDetails(id: number) {
        return this.http
            .get(
                this.salesOrderEndPoint.saleOrderPaymentDetails.replace(
                    '{id}',
                    id.toString()
                )
            )
            .pipe(
                map((item) => {
                    return item;
                })
            );
    }

    getShippingItems(id: number) {
        return this.http
            .get(this.itemsApiEndPoint.newList.replace('{id}', id.toString()))
            .pipe(
                map((items) => {
                    return items;
                })
            );
    }

    updateShippingOrder(data: any): Observable<any> {
        if (data.shippingStatusId == 2 || data.shippingStatusId == 5) {
            return this.http.post(this.apiEndPoint.takeOrder, data).pipe(
                map((res) => {
                    return res;
                })
            );
        }

        return this.http
            .put<boolean>(
                this.apiEndPoint.status.replace(
                    '{id}',
                    data.saleOrderId.toString()
                ),
                data
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    getBilledItems(id: number) {
        return this.http
            .get(this.apiEndPoint.billedItems.replace('{id}', id.toString()))
            .pipe(
                map((items) => {
                    return items;
                })
            );
    }
}
