import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ObservableDataService } from 'app/core/services/observable-data.service';
import * as toastr from 'toastr';
import { AuthenticationService } from './../../core/services/authentication.service';


@Injectable()
export class LoggedUserGuard implements CanActivate {

    constructor(private router: Router, private ods: ObservableDataService, private jwt: JwtHelperService, private auth: AuthenticationService) {
    }

    canActivate() {
        const sessionInfo = this.ods.getLoggedUser();

        if (sessionInfo.token) {
            if (!this.jwt.isTokenExpired(sessionInfo.token)) {
                return true;
            }else{
                   // Show the message.
                this.auth.logout();
                toastr.warning('Su sesión de usuario ha caducado', 'Sesión Caducada');
            }
        }

     
      
        this.router.navigate(['/login']);
        return false;
    }

    
}
