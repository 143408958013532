import { Component } from '@angular/core';

import { GLinkConfigService } from 'app/core/services/config.service';
import { glinkAnimations } from 'app/core/animations';

@Component({
    selector   : 'maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls  : ['./maintenance.component.scss'],
    animations : glinkAnimations
})
export class MaintenanceComponent
{
    /**
     * Constructor
     *
     * @param {GLinkConfigService} _glinkConfigService
     */
    constructor(
        private _glinkConfigService: GLinkConfigService
    )
    {
        // Configure the layout
        this._glinkConfigService.config = {
            layout: {
                navbar : {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer : {
                    hidden: true
                }
            }
        };
    }
}
