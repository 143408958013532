import { ModelBase } from '../../../../shared/models/model.base';
import {Category} from './category.type';
export class CategoryFlat extends ModelBase {
  companyId: string ;
  code: string;
  name: string ;
  description: string ;
  image: string ;
  status: boolean ;
  hierarchyLevel: number ;
  parent: Category;
  
  getTitle() {
    return `${this.name}`;
  }

}
