import { environment as env } from '../../../../environments/environment';

const BASE_URL = env.UpLink_ApiUrl; 
// const BASE_URL = env.Analytics_ApiUrl; 

export const SUBSCRIPTIONS_ANALYTICS_ENDPOINTS = {
    daily: `${BASE_URL}api/analytics/subscriptions/daily`,
    weekly: `${BASE_URL}api/analytics/subscriptions/weekly`,
    monthly: `${BASE_URL}api/analytics/subscriptions/monthly`,
    quarterly: `${BASE_URL}api/analytics/subscriptions/quarterly`,
    totals: `${BASE_URL}api/analytics/subscriptions/total`
};
