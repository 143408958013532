import { PagedApiResponse } from '../models/paged-api-response.type';


export class SharedMapper {

  mapPagedApiResponse<T>(data: any, mapper: (data: any, objectType?: any) => Array<T>, objectType?: any): PagedApiResponse<T> {
    const response = new PagedApiResponse<T>();
    response.count = data.count;
    response.rows = mapper(data.rows, objectType);

    return response;
  }
}
