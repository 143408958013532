import { environment as env } from '../../../../environments/environment';

const BASE_URL_CATEGORIES = `${env.UpLink_ApiUrl}api/products/category`;

export const CATEGORIES_ENDPOINTS = {
    create: `${BASE_URL_CATEGORIES}`,
    list: `${BASE_URL_CATEGORIES}/flatlist`,
    status: `${BASE_URL_CATEGORIES}/status`,
    listAll: `${BASE_URL_CATEGORIES}/listSimple/{id}`,
    get: `${BASE_URL_CATEGORIES}/{id}`,
    update: `${BASE_URL_CATEGORIES}/custom`,
    uploadFile: `${env.UpLink_ApiUrl}api/filemanager/file/upload`,
    filter: `${BASE_URL_CATEGORIES}/list-filter`,
};
