import { Injectable } from '@angular/core';

import { BaseApiService } from '../../../shared/services/base.api.service';
import { CONTACT_ENDPOINTS } from '../mycompany.endpoints';
import { ContactMapper } from '../mappers/contact.mapper';
import { Contact } from '../models/contact.type';

@Injectable()
export class ContactService extends BaseApiService<Contact>  {
  model = Contact;
  mapper = new ContactMapper();
  apiEndPoint: any = CONTACT_ENDPOINTS;
}
