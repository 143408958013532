export enum FilterOperator {
    equal = 'eq',
    notEqual = 'neq',
    startsWith = 'startswith',
    greaterOrEqualThan = 'gte',
    greaterThan = 'gt',
    lowerThanEqual = 'lte',
    lowerThan = 'lt',
    between = 'between',
    contains = 'contains',
    doesNotContain = 'doesnotcontain',
    endsWith = 'endswith',
    notEndsWith = 'notendswith',
    lestThan = 'lt',
    lessThanOrEqual = 'lte',
    containsIn = 'containsin',
    or = 'or',
    and = 'and'
}

export enum BooleanLogic {
    and = 'and',
    or = 'or'
}

export enum RecordAction {
    View,
    Add,
    Update,
    Delete
}

export enum MessageType {
    success,
    error,
    warning,
    info,
    question
}

export enum DataType {
    string,
    number,
    boolean,
    image,
    date
}

export enum ProductTypeEnum {
    item = 1,
    equipment = 2,
    part = 3
}
