import {
    Directive,
    Input,
    OnInit,
    ViewContainerRef,
    ElementRef,
} from '@angular/core';
import { ObservableDataService } from '../../core/services/observable-data.service';

@Directive({
    selector: '[ifHasPermission]',
})
export class HasPermissionDirective implements OnInit {
    @Input('ifHasPermission') permission: string;

    constructor(
        private _element: ElementRef,
        private _viewContainerRef: ViewContainerRef,
        private _ods: ObservableDataService
    ) {}

    ngOnInit() {
        const hostModule =
            this._viewContainerRef['_view'].root.ngModule.instance;
        const moduleName = hostModule.moduleName;

        const currentUser = this._ods.getLoggedUser();
        const { userModules } = currentUser;

        if (!userModules[moduleName][this.permission]) {
            this._element.nativeElement.remove();
        }
    }
}
