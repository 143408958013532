import { Injectable } from '@angular/core';
import { BaseMapper } from '../../../../shared/mappers/base.mapper';
import * as _ from 'lodash';
import { ProductGallery } from '../models/productGallery.type';



export class ProductGalleryMapper extends BaseMapper<ProductGallery> {


}
