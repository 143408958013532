import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BaseMapper } from '../mappers/base.mapper';
import { PagedApiResponse } from '../models/paged-api-response.type';
import { FilterType } from '../models/filter.type';
import { map, catchError } from 'rxjs/operators';
import { SharedMapper } from '../mappers/shared.mapper';
import { of } from 'rxjs';
import * as toastr from 'toastr';

@Injectable()
export class BaseApiService<T> {
    model: new () => T;
    apiEndPoint: any;
    mapper = new BaseMapper<T>();

    constructor(
        protected http: HttpClient,
        public sharedMapper: SharedMapper
    ) {}

    // tslint:disable-next-line:typedef
    sharedmapper() {
        return this.sharedMapper;
    }

    getList(filter?: FilterType): Observable<PagedApiResponse<T>> {
        return this.http.post(this.apiEndPoint.list, filter).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
    getListExcel(): Observable<PagedApiResponse<T>> {
        return this.http.get(this.apiEndPoint.exportExcel).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
    getListDonations(filter?: FilterType): Observable<PagedApiResponse<T>> {
        return this.http.post(this.apiEndPoint.listDonations, filter).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
    getListFind(filter?: FilterType): Observable<PagedApiResponse<T>> {
        return this.http.post(this.apiEndPoint.listFind, filter).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
    getListFindDonations(filter?: FilterType): Observable<PagedApiResponse<T>> {
        return this.http.post(this.apiEndPoint.listFindDonations, filter).pipe(
            map((res) => {
                return this.sharedMapper.mapPagedApiResponse(
                    res,
                    this.mapper.mapList,
                    this.model
                );
            })
        );
    }
    get(id: number): Observable<T> {
        console.log(this.apiEndPoint.item);
        return this.http
            .get(this.apiEndPoint.item.replace('{id}', id.toString()))
            .pipe(
                map((res) => {
                    return BaseMapper.map(res, this.model);
                })
            );
    }

    create(data: any): Observable<any> {
        return this.http.post<boolean>(this.apiEndPoint.base, data).pipe(
            map((res) => {
                return res;
            }),
            catchError((error) => {
                console.log(error);
                let errorText;

                if (error.status) {
                    if (error.status === 401) {
                        throw error;
                    }
                }

                errorText = error.error.message[0].message
                    ? error.error.message[0].message
                    : error.message;
                toastr.error(errorText);
                return of(false);
            })
        );
    }

    update(data: any): Observable<any> {
        return this.http
            .put<boolean>(
                this.apiEndPoint.item.replace('{id}', data.id.toString()),
                data
            )
            .pipe(
                catchError((error) => {
                    let errorText;
                    if (error.status) {
                        if (error.status === 401) {
                            throw error;
                        }
                    }

                    errorText = error.error.message
                        ? error.error.message
                        : error.error;
                    toastr.error(errorText);
                    return of(false);
                })
            );
    }

    delete(id: number): Observable<any> {
        return this.http
            .delete<boolean>(
                this.apiEndPoint.item.replace('{id}', id.toString())
            )
            .pipe(
                catchError((error) => {
                    let errorText;
                    if (error.status) {
                        if (error.status === 401) {
                            throw error;
                        }
                    }

                    errorText = error.error.message
                        ? error.error.message
                        : error.error;
                    toastr.error(errorText);
                    return of(false);
                })
            );
    }

    activationToggle(id: number): Observable<any> {
        return this.http
            .put<boolean>(
                this.apiEndPoint.activation.replace('{id}', id.toString()),
                null
            )
            .pipe(
                catchError((error) => {
                    let errorText;
                    if (error.status) {
                        if (error.status === 401) {
                            throw error;
                        }
                    }

                    errorText = error.error.message
                        ? error.error.message
                        : error.error;
                    toastr.error(errorText);
                    return of(false);
                })
            );
    }
}
