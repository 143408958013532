import { environment as env } from '../../../../environments/environment';

const BASE_URL = `${env.UpLink_ApiUrl}/api/shipping`;

export const SHIPPING_ENDPOINTS = {
    base: `${env.UpLink_ApiUrl}api/shipping`,
    list: `${env.UpLink_ApiUrl}api/shipping/{id}/list`,
    item: `${env.UpLink_ApiUrl}api/shipping/{id}`,
};

export const SHIPPING_ORDER_ENDPOINTS = {
    list: `${env.UpLink_ApiUrl}api/shipping/order/list`,
    listCustom: `${env.UpLink_ApiUrl}api/shipping/order/list/custom/admin`,
    listOrdersExportExcel: `${env.UpLink_ApiUrl}api/shipping/order/list/export`,
    item: `${env.UpLink_ApiUrl}api/shipping/order/{id}`,
    takeOrder: `${env.UpLink_ApiUrl}api/saleorderdiscount/checkout`,
    status: `${env.UpLink_ApiUrl}api/shipping/order/status/{id}`,
    base: `${env.UpLink_ApiUrl}api/shipping/order`,
    pdf: `${env.UpLink_ApiUrl}api/shipping/order/print/{id}`,
    saleOrder: `${env.UpLink_ApiUrl}api/saleorderdiscount/saleorderDisc/{id}`,
    billedItems: `${env.UpLink_ApiUrl}api/saleorderdiscount/getBilledItems/{id}`,
};

export const SHIPPING_CARRIER_ENDPOINTS = {
    base: `${env.UpLink_ApiUrl}api/shipping/carrier`,
    options: `${env.UpLink_ApiUrl}api/shipping/carrier/options`,
};

export const CUSTOMER_ENDPOINTS = {
    item: `${env.UpLink_ApiUrl}api/profilemanager/company/customers/{id}`,
};

export const SHIPPING_ITEMS_ENDPOINTS = {
    list: `${env.UpLink_ApiUrl}api/shipping/order/{id}/items`,
    newList: `${env.UpLink_ApiUrl}api/saleorderdiscount/saleorderDisc/{id}`,
};

export const SHIPPING_HISTORY_ENDPOINTS = {
    list: `${env.UpLink_ApiUrl}api/shipping/order/{id}/history/list`,
    /*base: `${env.UpLink_ApiUrl}api/shipping/order/history`,*/
    base: `${env.UpLink_ApiUrl}api/shipping/order/status/history`,
};

export const SHIPPING_BILLING_ENDPOINTS = {
    item: `${env.UpLink_ApiUrl}api/billing/{id}/invoice`,
    email: `${env.UpLink_ApiUrl}api/billing/{id}/invoice/email`,
    pdf: `${env.UpLink_ApiUrl}api/billing/{id}/invoice/PDF`,
};

export const SALES_ORDER_ENDPOINTS = {
    saleOrderPayment: `${env.UpLink_ApiUrl}/api/saleorders/paymentstatus/{id}`,
    saleOrderPaymentDetails: `${env.UpLink_ApiUrl}/api/saleorderdiscount/saleorderpaymentdetails/{id}`,
};
