import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatIconModule, MatMenuModule, MatRippleModule } from '@angular/material';

import { GLinkPipesModule } from 'app/core/pipes/pipes.module';

import { GLinkMaterialColorPickerComponent } from 'app/core/components/material-color-picker/material-color-picker.component';

@NgModule({
    declarations: [
        GLinkMaterialColorPickerComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,

        GLinkPipesModule
    ],
    exports: [
        GLinkMaterialColorPickerComponent
    ],
})
export class GLinkMaterialColorPickerModule
{
}
