import { NgModule } from '@angular/core';

import { GLinkCountdownComponent } from 'app/core/components/countdown/countdown.component';

@NgModule({
    declarations: [
        GLinkCountdownComponent
    ],
    exports: [
        GLinkCountdownComponent
    ],
})
export class GLinkCountdownModule
{
}
