// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    UpLink_ApiUrl: 'https://api-snc.sweetandcoffee.com.ec/',
    Fake_ApiUrl: 'https://jsonplaceholder.typicode.com/',
    apiKey: 'NO9IGn8yd2CO3MiWeWbLx21Mw1vsRdlq',
    googleMapsApiKey: 'AIzaSyC1riL3xuozYEy4-BaA6oBF-Tfr_S8TgKs',
    accessTokenLocalStorageKey: 'uplink_data',
    gCloudNotifImgBucket: '/imagenesPushNotifications/',
    restorePasswordUrl:
        'https://dev-domicilio.snc.com.ec/RestorePassword.php?ok=1&id=',
    accountValidateUrl:
        'https://dev-domicilio.snc.com.ec/CuentaVerificada.php?customer=name&token=',
    validateEccomerceAccount: true,
    correoCompany: 'uplink@grupo-link.com',
    urlCompany: 'webadmin-snc.sweetandcoffee.com.ec',
    urlEcommerceCompany: 'dev-domicilio.snc.com.ec',
    fulfilmentBy: 'SNC',
    logoUrl:
        'https://storage.googleapis.com/uplink-services/companies/28/profile/1590342235629_zes1qjjd0cq6btjqchb8tfydddw503.png',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
