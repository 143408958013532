import { NgModule } from '@angular/core';

import { GLinkIfOnDomDirective } from 'app/core/directives/glink-if-on-dom/glink-if-on-dom.directive';
import { GLinkInnerScrollDirective } from 'app/core/directives/glink-inner-scroll/glink-inner-scroll.directive';
import { GLinkPerfectScrollbarDirective } from 'app/core/directives/glink-perfect-scrollbar/glink-perfect-scrollbar.directive';
import { GLinkMatSidenavHelperDirective, GLinkMatSidenavTogglerDirective } from 'app/core/directives/glink-mat-sidenav/glink-mat-sidenav.directive';

@NgModule({
    declarations: [
        GLinkIfOnDomDirective,
        GLinkInnerScrollDirective,
        GLinkMatSidenavHelperDirective,
        GLinkMatSidenavTogglerDirective,
        GLinkPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        GLinkIfOnDomDirective,
        GLinkInnerScrollDirective,
        GLinkMatSidenavHelperDirective,
        GLinkMatSidenavTogglerDirective,
        GLinkPerfectScrollbarDirective
    ]
})
export class GLinkDirectivesModule
{
}
