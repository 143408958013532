import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { glinkAnimations } from '../../../core/animations';
import { RecordAction } from '../../enums';

@Component({
  selector: 'glink-dialogcontent',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  animations: glinkAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class DialogContentComponent implements OnInit {

  @Output() closeModal = new EventEmitter();
  @Input() dialogTitle: string;
  @Input() action: RecordAction;
  
  recordAction =  RecordAction;

  constructor() { }


  ngOnInit() {
  }

  close(){
    this.closeModal.emit();
  }

}
