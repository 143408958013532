import { ModelBase } from '../../../../shared/models/model.base';

export class ShippingCarrier extends ModelBase {
  companyId: number;
  name: '';
  contactNumber: '';
  feeBase: '';
  createdAt: Date = null;

  constructor() {
    super();
  }
}
