import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatCheckboxModule } from '@angular/material';

 
import { SharedModule } from '../../../../shared/shared.module';
import { ForgotPasswordComponent } from 'app/core/pages/authentication/forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const routes = [
    {
        path     : '',
        component: ForgotPasswordComponent
    }
];

@NgModule({
    declarations: [
        ForgotPasswordComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        CommonModule,
        SharedModule,
      
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule, 
        MatButtonModule,
     
        MatFormFieldModule,
        MatInputModule

         
    ]
})
export class ForgotPasswordModule
{
}
