import { NgModule } from '@angular/core';

import { LoginModule } from 'app/core/pages/authentication/login/login.module';
import { RegisterModule } from 'app/core/pages/authentication/register/register.module';
import { ForgotPasswordModule } from 'app/core/pages/authentication/forgot-password/forgot-password.module';
import { ResetPasswordModule } from 'app/core/pages/authentication/reset-password/reset-password.module';
import { LockModule } from 'app/core/pages/authentication/lock/lock.module';
import { MailConfirmModule } from 'app/core/pages/authentication/mail-confirm/mail-confirm.module';
import { ComingSoonModule } from 'app/core/pages/coming-soon/coming-soon.module';
import { Error404Module } from 'app/core/pages/errors/404/error-404.module';
import { Error500Module } from 'app/core/pages/errors/500/error-500.module';
import { MaintenanceModule } from 'app/core/pages/maintenance/maintenence.module';
import { SearchModule } from 'app/core/pages/search/search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [
        // Authentication
        LoginModule,
        RegisterModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        LockModule,
        MailConfirmModule,
        FormsModule,
        ReactiveFormsModule,

        // Coming-soon
        ComingSoonModule,

        // Errors
        Error404Module,
        Error500Module,



        // Maintenance
        MaintenanceModule,





        // Search
        SearchModule,


    ]
})
export class PagesModule
{

}
