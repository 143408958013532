import { environment as env } from '../../../environments/environment';

export class CredentialsType {
    userName: string;
    password: string;
    domain: string;
    clientId: number;
    grantType: string;
    refreshToken: string;

    constructor(grantType: string = 'password') {
        //  const url =  env.production === true ? location.hostname : 'proalco.uplinkbusiness.com';
        const url = location.hostname.includes('localhost')
            ? /*'wasabi.uplinkbusiness.com/login' */ 'dev-snc.uplinkbusiness.com' /*'pedidos.tia.com.ec'*/
            : location.hostname;
        // 'dev-sin-fronteras.uplinkbusiness.com' : location.hostname;
        this.domain = url;
        // this.clientId = env.defaultClientId;
        this.grantType = grantType;
    }

    setUserNamePasswordValues(userName, password) {
        this.userName = userName;
        this.password = password;
    }
}
