
import { Injectable } from '@angular/core';

import { UserSessionType } from '../models/user-session.type';
import { environment as env } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ObservableDataService {

    // Logged User.    
    private _loggedUser: BehaviorSubject<UserSessionType> = new BehaviorSubject(this.getDefaultSession(''));

    get loggedUser() {
        return this._loggedUser.asObservable();
    }

    constructor() { }

    getDefaultSession(url: string) {
        let defaultSession = this.getLoggedUser();

        if (!defaultSession) {
            defaultSession = new UserSessionType();
            defaultSession.avatarUrl = url;
        }
        
        return defaultSession;
    }

    setloggedUser(value: UserSessionType) {
        this._loggedUser.next(value);
    }

    getLoggedUser(): UserSessionType {
        if (localStorage.getItem(env.accessTokenLocalStorageKey)) {
            return <UserSessionType>JSON.parse(localStorage.getItem(env.accessTokenLocalStorageKey));
        }

       return new UserSessionType();
    }
}
